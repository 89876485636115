const STRONG_PASSWORD = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-\"!@#%&\/,><\':;|_~`])\S{6,50}$/;
const PHONE_NUMBER = /^\d{10}$/;
const CHECK_DECIMAL = /^[0-9]*\.?[0-9]{0,2}$/;
const ONLY_DIGITS = /^\d+$/;
const IS_LOCALHOST = /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/;

export {
    STRONG_PASSWORD,
    CHECK_DECIMAL,
    ONLY_DIGITS,
    IS_LOCALHOST,
    PHONE_NUMBER,
};
