import * as loanAccountTypes from '../constants/actions/loan-account';
import * as types from '../constants/actions/app';

const defaultState = {
  loading: false,
  modal: {
    isVisible: false,
    id: null,
    data: null
  },
  loanAccounts: null,
  receipts: {},
  selectedLoanAccount: null,
  transactionFee: null,
  notice: '',
  payment: {amount: ''},
  message: {},
  showBkUdfPopup: false,
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case types.SHOW_MESSAGE_BAR :
      return {
        ...state, 
        message: {
          status: action.payload.status, 
          text: action.payload.text
        }
      }
    case types.HIDE_MESSAGE_BAR :
      return {
        ...state, 
        message: {
          status: '', 
          text: ''
        }
      }
    case types.SHOW_LOADING :
      return {...state, loading: true}
    case types.HIDE_LOADING :
      return {...state, loading: false}
    case `${loanAccountTypes.LINK_LOAN_ACCOUNT}_REJECTED` :
      return {...state, loading: false}
    case `${loanAccountTypes.NOTIFY_LOAN_ACCOUNT_LINKING}_PENDING` :
      return {...state, loading: true}
    case `${loanAccountTypes.NOTIFY_LOAN_ACCOUNT_LINKING}_FULFILLED` :
      return {...state, loading: false}
    case loanAccountTypes.SET_LOAN_ACCOUNTS :
      return {...state, loanAccounts: action.payload}
    case loanAccountTypes.SET_SHOW_BK_UDF_POPUP :
      return {...state, showBkUdfPopup: action.payload}
    case loanAccountTypes.SET_SELECTED_LOAN_ACCOUNT :
      return {...state, selectedLoanAccount: action.payload}
    case types.SELECT_PAYMENT_METHOD :
      return {
        ...state, 
        payment: {
          ...state.payment, 
          transactionFee: action.payload
        }
      }
    case types.SET_PAYMENT_AMOUNT :
      return {...state, payment: {...state.payment, amount: action.amount}}
    case `${types.MAKE_PAYMENT}_REJECTED` :
      return {
        ...state, loading: false, notice: action.payload.data.message, payment: {amount: ''}
      }
    case `${types.FETCH_RECEIPTS}_PENDING` :
      return {...state, loading: true}
    case `${types.FETCH_RECEIPTS}_FULFILLED` :
      let receiptsResp = action.payload.data;

      if (receiptsResp.status === 1) {
        return {
          ...state,
          loading: false,
          receipts: {
            items: receiptsResp.result.receipts, 
            page: receiptsResp.result.currentPage,
            totalPages: receiptsResp.result.totalPage, 
            totalItems: receiptsResp.result.numberOfElements
          }
        }
      }

      return state;
    case types.SET_SELECTED_RECEIPT :
      return {...state, selectedReceipt: action.payload}
    default :
      return state
  }
}