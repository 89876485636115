import React, { Component } from 'react';

// Import components
import Dropdown from '../../../components/application/dropdown/index';
import DropdownItem from '../../../components/application/dropdown/item';

class Collateral extends Component {
  /**
   * Handle the event to delete a loan account.
   */
  handleDelete = () => {
    this.props.onDelete();
  }

  /**
   * Render the collateral information.
   */
  renderCollateral() {
    let { year, make, model } = this.props;

    if (year === '' && make === '' && model === '') {
      return <h5 className="loan_account__no-collateral"> </h5>
    }

    return (
      <div>
        <span className="loan-account__collateral">
          {`${this.props.year} ${this.props.make}`}
        </span>
        <h5 className="loan_account__model">{this.props.model}</h5>
      </div>
    )
  }

  /**
   * Render the component view.
   */
  render() {
    return (
      <div className="loan-account__header">
        {this.renderCollateral()}

        {/**
         ** Options
         **/}
        {this.props.withOptions ? (
          <div className="load-account__options-wrapper">
            <Dropdown alignToRight={true} className="options-dropdown" handler={() =>
              <i className="fas fa-ellipsis-v size-16 icon--20px color-gray"></i>
            }>
              <DropdownItem className="options-dropdown__item" value={this.props.id} onClick={this.handleDelete}>
                {this.props.whiteLabel.labels['Delete Loan']}
              </DropdownItem>
            </Dropdown>
          </div>
        ) : null}
      </div>
    );
  }
}

Collateral.defaultProps = {
  withOptions: true
}

export default Collateral;