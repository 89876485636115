import * as types from '../constants/actions/schedule';

const defaultState = {
  status: '0',
  paymentType: null,
  sortBy: 1,
  scheduledPayments: []
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case types.SET_OPTION :
      let newState = {...state};
      newState[action.payload.key] = action.payload.value;

      return newState;
    case types.SET_SCHEDULED_PAYMENTS :
      return {...state, scheduledPayments: action.payload}
    default:
      return state;
  }
}