export const GET_CLIENT_DETAILS = 'GET_CLIENT_DETAILS';
export const GET_BACKGROUND_IMAGE = 'GET_BACKGROUND_IMAGE';
export const FETCH_PAYMENT_CARDS = 'FETCH_PAYMENT_CARDS';
export const SET_PAYMENT_CARDS = 'SET_PAYMENT_CARDS';
export const ADD_CARD = 'ADD_CARD';
export const ADD_ACH = 'ADD_ACH';
export const DELETE_CARD = 'DELETE_CARD';
export const SET_TRANSACTION_MARGIN = 'SET_TRANSACTION_MARGIN';
export const SET_CLIENT_IMAGES = 'SET_CLIENT_IMAGES';
export const GET_PAYMENT_FREQS = 'GET_PAYMENT_FREQS';
