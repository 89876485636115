import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { onlyNumbers } from '../../helpers/app';
import PayixButton from '../../containers/payix-button';
import Datefield,{DatefieldMin18} from '../application/form/datefield';
import '../../styles/modules/form.css';
import _ from 'lodash';
import PasswordShowHide from "../../components/password-show-hide";

import store from '../../config/store';
import { isEmail } from 'validator';
import { STRONG_PASSWORD } from '../../constants/regex';

const validate = (values, _parent) => {
  const errors = {};
  if (!values.email || !isEmail(values.email)) {
    errors.email = _parent.whiteLabel.labels['This must be a valid e-mail'];
  }

  if (!values.password) {
    errors.password = _parent.whiteLabel.labels['This field is required'];
  }
  if (!values.fname) {
    errors.fname = _parent.whiteLabel.labels['This field is required'];
  }
  if (!values.lname) {
    errors.lname = _parent.whiteLabel.labels['This field is required'];
  }
  if (!values.stateid || values.stateid < 1) {
    errors.stateid = _parent.whiteLabel.labels['Please, select a State/Province'];
  }
  if (!values.language || values.language < 1) {
    errors.language = _parent.whiteLabel.labels['Please, select a Language'];
  }
  if (!values.phoneno) {
    errors.phoneno = _parent.whiteLabel.labels['This field is required'];
  }
  if (!values.password) {
    errors.password = _parent.whiteLabel.labels['This field is required'];
  }

  if (
    !values.passwordConfirmation ||
    values.password !== values.passwordConfirmation
  ) {
    errors.passwordConfirmation =
      _parent.whiteLabel.labels['Password and password confirmation must match'];
  }

  if (!STRONG_PASSWORD.test(values.password)) {
    errors.password =
    _parent.whiteLabel.labels['Must contain one lower & uppercase character, one number, one special &  must be 8-14 characters long'];
  }

  return errors;
};

const renderInput = ({
  input,
  label,
  type,
  htmlFor,
  maxLength,
  autoComplete,
  meta: { touched, error },
  ...rest
}) => (
  <div className="form-group">
    <label htmlFor={htmlFor} className="form-group__label">
      {label}
    </label>
    <input
      className="form-group__input"
      id={htmlFor}
      {...input}
      {...rest}
      type={type}
      maxLength={maxLength}
      autoComplete={autoComplete}
    />
    {touched && error && <span className="form-group__error">{error}</span>}
  </div>
);

const renderInputPass = ({
  input,
  label,
  type,
  htmlFor,
  maxLength,
  autoComplete,
  meta: { touched, error },
  ...rest
}) => (
  <div className="form-group">
    <label htmlFor={htmlFor} className="form-group__label">
      {label}
    </label>
    <PasswordShowHide
      className="form-group__input"
      id={htmlFor}
      input={input}
      rest={rest}
      type={type}
      autoComplete={autoComplete}
    />
    {touched && error && <span className="form-group__error">{error}</span>}
  </div>
);

const renderSelect = ({
  input,
  label,
  htmlFor,
  states,
  whiteLabel,
  meta: { touched, error }
}) => {
  states = _.orderBy(states, ['stateName'],['asc']);
  states.unshift({'stateCode':'0', 'stateName': whiteLabel.labels['Select']});
  const options = states.map((s, i) => (
    s.stateName === 'Select' && i > 1 ? ('') : i === 0 ?
    (<option key={s.stateCode} value={s.id}>{s.stateName}</option>):
    (<option key={s.stateCode} value={s.id}>{s.stateName}</option>)
  ));
  return (
    <div className="form-group">
      <label htmlFor={htmlFor} className="form-group__label">
        {label}
      </label>
      <select className="form-group__input" id={htmlFor} {...input}>
        {options}
      </select>
      {touched && error && <span className="form-group__error">{error}</span>}
    </div>
  );
};

const renderLanguageSelect = ({
  input,
  label,
  htmlFor,
  languages,
  whiteLabel,
  meta: { touched, error }
}) => {
  languages = _.orderBy(languages, ['name'],['asc']);
  languages.unshift({'code':'', 'name':whiteLabel.labels['Select']});
  const options = languages.map((s, i) => (
    s.name === 'Select' && i > 1 ? ('') : i === 0 ?
    (<option key={s.code} value={s.code}>{s.name}</option>):
    (<option key={s.code} value={s.code}>{s.name}</option>)
  ));
  return (
    <div className="form-group">
      <label htmlFor={htmlFor} className="form-group__label">
        {label}
      </label>
      <select className="form-group__input" id={htmlFor} {...input} >
        {languages.map(language => (
          <option key={language.id} value={language.code}>{language.name}</option>
        ))}
      </select>
      {touched && error && <span className="form-group__error">{error}</span>}
    </div>
  );
};

let SignUpForm = props => {
  const { handleSubmit, valid, states, isSsn, languages, disableQuickLink, whiteLabel, onLanguageChange, initialValues: { socialId, email } } = props;

  /**
   * Render the SSN Or DBO Field.
   */
  const renderSsnOrDbo = (isSsn) => {
    if(!disableQuickLink){

      if (isSsn) {
        return (
          <div className="half-responsive">
            <Field
              name="ssn"
              type="text"
              htmlFor="txtSsn"
              component={renderInput}
              label={whiteLabel.labels['Last 4 SSN']}
              maxLength="4"
              autoComplete="off"
              normalize={onlyNumbers}
            />
          </div>
        );
      }

      return (
        <div className="half-responsive">
          <Field
            name="dob"
            type="text"
            htmlFor="txtDbo"
            component={DatefieldMin18}
            label={whiteLabel.labels['DOB (optional)']}
          />
        </div>
      );
    }
  }

  /**
   * Render the Languages Field.
   */
  const renderLanguages = (languages) => {
      if (languages.length > 1) {
        return (
          <div className="half-responsive pull-right">
            <Field
              name="language"
              type="text"
              htmlFor="language"
              languages={languages}
              component={renderLanguageSelect}
              onChange={onLanguageChange}
              label={whiteLabel.labels['Select Language']}
              whiteLabel={whiteLabel}
              autoComplete="off"
            />
          </div>
        );
    }
  }

  let passwordInfo = store.getState().client.data.passwordInfo;
  return (
    <form onSubmit={handleSubmit}>
      {/* Email */}
      <Field
        name="email"
        type="email"
        htmlFor="txtEmail"
        component={renderInput}
        label={whiteLabel.labels['Email Address']}
        autoComplete="email"
        disabled={(socialId && email) ? true : false}
      />
  
      <div>
        {/* Name */}
        <div className="half-responsive">
          <Field
            name="fname"
            type="text"
            htmlFor="txtFName"
            component={renderInput}
            label={whiteLabel.labels['First Name']}
            autoComplete="given-name"
          />
        </div>
  
        {/* Lastname */}
        <div className="half-responsive">
          <Field
            name="lname"
            type="text"
            htmlFor="txtLName"
            component={renderInput}
            label={whiteLabel.labels['Last Name']}
            autoComplete="family-name"
          />
        </div>
      </div>
  
      {/* State */}
      <Field
        name="stateid"
        type="text"
        htmlFor="txtStateId"
        states={states}
        component={renderSelect}
        label={whiteLabel.labels['State']}
        whiteLabel={whiteLabel}
        autoComplete="off"
      />
  
      {/* Phone */}
      <Field
        name="phoneno"
        type="text"
        htmlFor="txtPhoneNumber"
        component={renderInput}
        label={whiteLabel.labels['Mobile Number']}
        maxLength="10"
        autoComplete="tel"
        normalize={onlyNumbers}
      />
  
      {/* SSN Or DBO */}
      {renderSsnOrDbo(isSsn)}

      {/* Languages */}
      {renderLanguages(languages)}
  
      {/* Password */}
      {!socialId && (
        <Field
          name="password"
          type="password"
          htmlFor="txtPassword"
          component={renderInputPass}
          className="form-group__input" 
          label={whiteLabel.labels['Password']}
          autoComplete="new-password"
        />
      )}
  
      {/* Password Confirmation */}
      {!socialId && (
        <Field
          name="passwordConfirmation"
          type="password"
          className="form-group__input" 
          htmlFor="txtPasswordConfirm"
          component={renderInputPass}
          label={whiteLabel.labels['Confirm Password']}
          autoComplete="off"
        />
      )}
      
      {passwordInfo && (
        <div className="password-noti-in">
        <i className="fa fa-info-circle" aria-hidden="true"></i>&nbsp;
          <span className="password-notification">{passwordInfo}</span>
        </div>
      )}
      <div className="form-group">
        <PayixButton className="button--full" type="submit" disabled={!valid}>
            {whiteLabel.labels['Sign Up']}
        </PayixButton>
      </div>
    </form>
  );
};

SignUpForm = reduxForm({
  form: 'signup',
  destroyOnUnmount: false,
  enableReinitialize: true,
  validate,
})(SignUpForm);

export default SignUpForm;