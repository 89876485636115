import { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { apiImpersonateSignIn } from '../api/auth';
import { Application } from '../core/app';
import { userSignedIn } from '../actions/auth';
const queryString = require('query-string');

class Impersonate extends Component {
  componentDidMount() {
    const queryParams = queryString.parse(this.props.location.search);

    apiImpersonateSignIn(queryParams)
      .then((response) => {
        Application.logout();
        let data = response.data;

        if (data.status === 1 && data.statusCode === 200) {
          if (data.result.accessToken && data.result.accessToken.length > 0) {
            Application.configureAuthUser(data.result);
            this.props.userSignedIn(true);
            this.props.history.push('/home');
          }
        } else {
          this.props.history.push('/login');
        }
      })
      .catch(() => {
        Application.logout();
        this.props.history.push('/login');
      });
  }

  /**
   * Render the component view.
   */
  render() { 
    return null;
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    userSignedIn
  }, dispatch);
}
 
export default withRouter(connect(null, mapDispatchToProps)(Impersonate));