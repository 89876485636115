import React, { Component } from 'react';
import { isEmail } from 'validator';

// Import components
import { Field, reduxForm } from 'redux-form';
import PayixButton from '../../containers/payix-button';

const validate = (values, _parent) => {
  const errors = {};

  if (!values.email || !isEmail(values.email)) {
    errors.email = _parent.whiteLabel.labels['Please enter a valid email address'];
  }

  return errors;
};

const renderField = (data) => {
  const {input, type, name, maxLength, meta: { touched, error }} = data;
  return (
    <div>
      <input className="form-group__input" id={name} {...input} maxLength={ maxLength } type={type} />
      { touched && error && <span className="form-group__error">{error}</span> }
    </div>
  )
}

class AddEmailForm extends Component {
  /**
   * Render the component view.
   */
  render() {
    let { handleSubmit, valid } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label className="form-group__label" htmlFor="email">{this.props.whiteLabel.labels['Email Address']}</label>
          <Field name="email" type="text" maxLength="100" component={ renderField } />
        </div>

        <div className="form-group">
          <PayixButton className="button--full" type="submit" disabled={!valid}>
              {this.props.whiteLabel.labels['Submit']}
          </PayixButton>
        </div>
      </form>
    )
  }
}

AddEmailForm = reduxForm({
  form: 'addEmailForm',
  validate
})(AddEmailForm);
 
export default AddEmailForm;