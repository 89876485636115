import React from 'react';
import { render } from 'react-dom';
import './styles/base/main.css';
import './styles/layout/application.css';
import './styles/base/helpers.css';
import App from './App';
import { unregister } from './registerServiceWorker';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import store from './config/store';

/**
 * Some older browsers do not support many of the functions they have added now, 
 * which is why we inject this, to support these features in old browsers.
 */

import "babel-polyfill";

/**
 * First we will load all of this project's JavaScript dependencies which
 * includes React and other libraries. It is a great starting point when
 * building robust, powerful web applications using React.js.
 */

require('./bootstrap');

const target = document.getElementById('root');

render(
  <BrowserRouter>
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>, target);

unregister();
