import * as types from '../constants/actions/auth';

const defaultState = {
  loggedIn: null,
  loading: false,
  states: [],
  loadingStates: false,
  canShowOTP: false,
  formValues: {},
  otpMessage: '',
  userData: null
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case `${types.SIGN_IN}_FULFILLED`:
      return {...state, loggedIn: action.payload};
    case types.LOG_OUT:
      return {...defaultState, loggedIn: false};
    case `${types.GET_STATES}_PENDING`:
      return {...state, loadingStates: true};
    case `${types.GET_STATES}_FULFILLED`:
      const baseState = [{stateCode: 'select', stateName: 'Select'}];
      const { states } = action.payload.data.result;
      return {...state, states: baseState.concat(states), loadingStates: false};
    case types.TOGGLE_TERMS_MODAL:
      return {...state, showTermsModal: (!state.showTermsModal)};
    case `${types.SOCIAL_SIGN_UP}_FULFILLED`:
      return {
        ...state, 
        canShowOTP: true, 
        formValues: action.payload.formValues,
        otpMessage: action.payload.message
      };
    case types.OTP_FORM_FALSE:
      return {...state, canShowOTP: false};
    case types.SET_AUTH_USER_DATA :
      return {...state, userData: action.payload}
    case types.SET_SOCIAL_LOGIN :
      return {
        ...state, 
        formValues: {
          fname: action.payload.firstName,
          lname: action.payload.lastName,
          email: action.payload.email,
          socialId: action.payload.socialId,
          type: action.payload.type
        }
      }
    default:
      return state;
  }
};
