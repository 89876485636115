import React, { Component } from 'react';
import onClickOutside from "react-onclickoutside";
import P from 'prop-types';
import '../../../styles/modules/widgets/dropdown.css';

class Dropdown extends Component {
  state = {
    isOpen: false
  }

  /**
   * Show or close the 
   */
  toggle = () => {
    this.setState((state) => {
      return {
        isOpen: !state.isOpen
      }
    });
  }

  handleClickOutside = () => {
    if (this.state.isOpen) {
      this.toggle();
    }
  };

  handleChangeItem = (value) => {
    this.props.onChange(value);
    this.toggle();
  }

  handleCustomClick = (handler) => (value) => {
    handler(value);
    this.toggle();
  }

  renderOptions = () => {
    return React.Children.map(this.props.children, child => {
      let props = {
        onClick: this.handleChangeItem
      };

      if (child.props.onClick) {
        props = {
          onClick: this.handleCustomClick(child.props.onClick) 
        }
      }

      return React.cloneElement(child, props);
    })
  }

  /**
   * Render the component view.
   */
  render() {
    let { isOpen } = this.state;
    let { alignToRight, className } = this.props;
    
    let dropdownItemsCss = 'dropdown__items';
    
    if (alignToRight) {
      dropdownItemsCss += ' dropdown__items--align-right';
    }

    if (className) {
      dropdownItemsCss += ' ' + className;
    }

    if (isOpen) {
      dropdownItemsCss += ' dropdown__items--open';
    }

    return (
      <div className="dropdown">
        <button className="dropdown__handler" onClick={this.toggle}>
          {this.props.handler()}
        </button>
        
        <ul className={dropdownItemsCss}>
          { this.renderOptions() }
        </ul>
      </div>
    )
  }
}

Dropdown.defaultProps = {
  alignToRight: false
}

Dropdown.propTypes = {
  alignToRight: P.bool,
  className: P.string
}
 
export default onClickOutside(Dropdown);