import React from 'react'
import '../../styles/modules/whole-screen-loader.css';

let WSLoader = ({text}) => {
  return (
    <div className="whole-screen-loader">
      <span className="whole-screen-loader__spinner fas fa-spinner"></span>
    </div>
  );
};

export default WSLoader;
