import * as types from '../constants/actions/chat';
import { requestMessages, requestSendMessage } from '../api/chat';

export function fetchMessagesFinished() {
  return {
    type: types.FETCHING_MESSAGES_FINISHED
  }
}

export function fetchMessages(page = 1) {
  return function(dispatch) {
    dispatch({type: types.FETCHING_MESSAGES});

    return requestMessages(page)
      .then((response) => {
        let data = response.data;

        if (data.status === 1 && data.statusCode === 200) {
          dispatch({
            type: types.SET_MESSAGES,
            payload: {
              currentPage: data.result.currentPage,
              totalPage: data.result.totalPage,
              messages: data.result.data,
              append: (page !== 1) ? true : false
            }
          });
        } else {
          dispatch(fetchMessagesFinished());
        }
      })
      .catch(() => {
        dispatch(fetchMessagesFinished());
      })
  }
}

export function sendMessage(message) {
  return {
    type: types.SEND_MESSAGE,
    payload: requestSendMessage(message)
  }
}