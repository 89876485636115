import React, { Component } from 'react';
import '../../styles/modules/msg-bar.css';

class MsgBar extends Component {
  /**
   * Set timeout for dismiss the msg bar if currently is displayed.
   */
  componentDidUpdate() {
    if (this.props.text !== undefined && this.props.text !== '') {
      this.timeOut = setTimeout(() => {
        this.props.onDismiss();
      }, 6000);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeOut);
  }

  /**
   * Render the component view.
   */
  render() {
    let { text, status } = this.props;
    let styles = 'msg-bar animated';

    if (status === 'success') {
      styles += ' msg-bar--success';
    } else if (status === 'error') {
      styles += ' msg-bar--error';
    }

    // Set the animations.
    if (text !== undefined) {
      if (text !== '') {
        styles += ' fadeInDown';
      } else if (text === '') {
        styles += ' fadeOutUp';
      }
    } else {
      styles += ' msg-bar--no-display';
    }
    
    return (
      <div className={ styles }>
        <span className="msg-bar__text">{ text }</span>
      </div>
    );
  }
}
 
export default MsgBar;
