import React, { Component } from 'react';

class DropDrownItem extends Component {
  /**
   * Handle the event click over the item.
   *  
   * @param event
   */
  handleItemClick = () => {
    if (this.props.onClick) {
      this.props.onClick(this.props.value);
    }
  }

  /**
   * Render the component view.
   */
  render() {
    let { className } = this.props;
    let dropdownItemCss = 'dropdown__item';

    if (className) {
      dropdownItemCss += ' ' + className;
    }

    return (
      <li className={dropdownItemCss} onClick={ this.handleItemClick }>
        { this.props.children }
      </li>
    )
  }
}
 
export default DropDrownItem;