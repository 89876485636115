import React, { Component } from 'react';

// Import assets
import warningIcon from '../../assets/images/warning.png';

// Import components
import PayixButton from '../../containers/payix-button';

class DeleteRecurringScheduledPaymentModal extends Component {
  /**
   * Render the component view.
   */
  render() { 
    return (
      <div className="modal--width-500px">
        <div className="modal__header">
          <div className="modal__header-content--centered">
            <img src={warningIcon} alt="" />
          </div>
        </div>

        <div className="modal__body">
          <div className="modal__body-content--pad35 text-center">
            <p className="modal-paragraph">{this.props.whiteLabel.labels['Are you sure you want to delete this recurring schedule?']}</p>

            <div className="form-group form-group--row-layout">
              <PayixButton className="size-50per margin-right-10" onClick={this.props.onCancel}>
                  {this.props.whiteLabel.labels['Cancel']}
              </PayixButton>

              <PayixButton className="size-50per margin-right-10" onClick={this.props.onRecurringDelete}>
                  {this.props.whiteLabel.labels['Confirm Delete']}
              </PayixButton>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
 
export default DeleteRecurringScheduledPaymentModal;