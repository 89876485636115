import React, { Component } from 'react';
import { connect } from 'react-redux';

class Status extends Component {
  /**
   * Render the component view.
   */
  render() { 
    return (
      <div className="form-group form-group--horizontal status-filter">
        <label className="form-group__label form-group__label--horizontal">{this.props.whiteLabel.labels['Status']}</label>
        <select className="form-group__input custom-select" value={ this.props.selected }
          onChange={ this.props.onChange }>
          <option value="0">{this.props.whiteLabel.labels['Scheduled']}</option>
          <option value="3">{this.props.whiteLabel.labels['Deleted']}</option>
          <option value="2">{this.props.whiteLabel.labels['Declined']}</option>
          <option value="-1">{this.props.whiteLabel.labels['ALL']}</option>
        </select>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
    return {
        whiteLabel: state.whiteLabel
    };
};

export default connect(mapStateToProps)(Status);
