import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isEmail } from 'validator';
import { onlyNumbers } from '../../helpers/app';
import { formValueSelector } from 'redux-form';

// Import components
import { Field, reduxForm } from 'redux-form';
import PayixButton from '../../containers/payix-button';

const validate = (values, _parent) => {
  const errors = {};

  if (!values.email && !values.phoneNo) {
    errors.all = _parent.whiteLabel.labels['Some field should be filled'];
  }

  if (values.email) {
    if (!isEmail(values.email)) {
      errors.email = _parent.whiteLabel.labels['This must be a valid e-mail'];
    }
  }

  return errors;
};

const renderField = (data) => {
  const {input, type, name, maxLength, meta: { touched, error }} = data;
  return (
    <div>
      <input className="form-group__input" id={name} {...input} maxLength={ maxLength } type={type} />
      { touched && error && <span className="form-group__error">{error}</span> }
    </div>
  )
}

class SendReceiptForm extends Component {
  /**
   * Render the component view.
   */
  render() {
    let { valid } = this.props;
    let someFieldFull = (this.props.email || this.props.phoneNo) ? true : false;

    return (
      <form onSubmit={ this.props.handleSubmit }>
        <div className="form-group">
          <label className="form-group__label" htmlFor="email">{this.props.whiteLabel.labels['Email Address']}</label>
          <Field name="email" type="text" autoComplete='email' component={ renderField } />
        </div>

        <div className="form-group">
          <label className="form-group__label" htmlFor="phoneNo">{this.props.whiteLabel.labels['Mobile Number']}</label>
          <Field name="phoneNo" type="text" maxLength="10" normalize={onlyNumbers} autoComplete="tel" component={ renderField } />
        </div>

        <div className="form-group">
          <PayixButton className="button--full" type="submit" disabled={!valid || !someFieldFull}>
              {this.props.whiteLabel.labels['Send Receipt']}
          </PayixButton>
        </div>
      </form>
    );
  }
}

let selector = formValueSelector('sendReceiptForm');

SendReceiptForm = reduxForm({
  form: 'sendReceiptForm',
  initialValues: {
    email: '',
    phoneNo: ''
  },
  validate
})(SendReceiptForm);

export default connect(
  state => ({
    email: selector(state, 'email'),
    phoneNo: selector(state, 'phoneNo'),
    whiteLabel: state.whiteLabel
  })
)(SendReceiptForm)
