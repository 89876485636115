import React, { Component } from 'react';

class Profile extends Component {
  /**
   * Render the component view.
   */
  render() {
    let { color } = this.props;

    let userImage = {};
    let carImage = '';
    let editPhotoBtnStyles = {
      backgroundColor: color
    }

    if (this.props.userImage !== '') {
      userImage.background = 'url(' + this.props.userImage + ')';
      userImage.borderColor = color;
    }

    if (this.props.vehicleImage !== '') {
      carImage = this.props.vehicleImage;
    }

    return (
      <figure className="load-account__picture">
        {/* Vehicle Image */}
        <div className="loan-account__vehicle">
          <img className="loan-account__vehicle-img" src={carImage} alt="" />
        </div>

        {/* User Image */}
        <div className="loan-account__user-image" style={userImage}>
          <i className="fas fa-camera edit-photo-btn loan-account__user-image-edit"
            style={editPhotoBtnStyles} onClick={this.props.onUploadUserImage}>
          </i>
        </div>

        <i className="fas fa-camera edit-photo-btn loan-account__vehicle-image-edit"
          style={editPhotoBtnStyles} onClick={this.props.onUploadVehicleImage}>
        </i>
      </figure>
    );
  }
}
 
export default Profile;