import { ENDPOINTS } from '../constants/api';

/**
 * Fetch all chat messages from the server.
 */
export function requestMessages(page) {
  let endpoint = ENDPOINTS.getMessage + '?page=' + page + '&limit=10';

  return window.axios.get(endpoint);
}

/**
 * Send a new chat message.
 *
 * @param {*} message
 */
export function requestSendMessage(message) {
  let endpoint = ENDPOINTS.sendMessage;

  return window.axios.post(endpoint, {
    message
  });
}
