import React, { Component } from 'react';

class SortBy extends Component {
  /**
   * Render the component view.
   */
  render() { 
    return (
      <div className="form-group form-group--horizontal filter-inline">
        <label className="form-group__label form-group__label--horizontal">{this.props.whiteLabel.labels['SORT BY']}</label>
        <select className="form-group__input custom-select" value={ this.props.selected } 
          onChange={ this.props.onChange }>
          <option value="1">{this.props.whiteLabel.labels['Payment Date Ascending']}</option>
          <option value="2">{this.props.whiteLabel.labels['Payment Date Descending']}</option>
          <option value="3">{this.props.whiteLabel.labels['Payment Amount Ascending']}</option>
          <option value="4">{this.props.whiteLabel.labels['Payment Amount Descending']}</option>
        </select>
      </div>
    );
  }
}
 
export default SortBy;