import React, { Component } from 'react';
import { onlyNumbers } from '../helpers/app';

// Import components
import { Field, reduxForm } from 'redux-form';
import PayixButton from '../containers/payix-button';
import ReactTooltip from 'react-tooltip';

// Import assets
import infoRoutingImg from '../assets/images/info-routing-num.png';
import { ONLY_DIGITS } from '../constants/regex';

const validate = (values, _parent) => {
  const errors = {};

  if (!values.bankName) {
    errors.bankName = _parent.whiteLabel.labels['This field is required!'];
  }

  if (!values.accountType) {
    errors.accountType = _parent.whiteLabel.labels['This field is required!'];
  }

  if (!values.name) {
    errors.name = _parent.whiteLabel.labels['This field is required!'];
  }

  if (!values.routingNumber) {
    errors.routingNumber = _parent.whiteLabel.labels['This field is required!'];
  } else if (values.routingNumber.length < 9) {
    errors.routingNumber = _parent.whiteLabel.labels['minimum length should be 9 digits'];
  }

  if (!values.accountNumber) {
    errors.accountNumber = _parent.whiteLabel.labels['This field is required!'];
  } else if (values.accountNumber.length < 4) {
    errors.accountNumber = _parent.whiteLabel.labels['It should be between 4 to 25 characters'];
  }

  if (values.accountNumber && !ONLY_DIGITS.test(values.accountNumber)) {
    errors.accountNumber = 'Account number needs to be only numbers'
  }

  if (values.accountNumber && values.accountNumber.length > 17) {
    errors.accountNumber = 'Account number cannot exceed 17 numbers'
  }

  return errors;
};

const renderField = ({input, type, name, maxLength, meta: { dirty, active, touched, error }}) => (
  <div>
    <input className="form-group__input" id={name} {...input} maxLength={ maxLength } type={type} />
    { (dirty || active || touched) && error && <span className="form-group__error">{error}</span> }
  </div>
)

const renderSelect = ({input, name, meta: { dirty, active, touched, error }, children}) => (
  <div>
    <select className="form-group__input" {...input}>
      { children }
    </select>
    { (dirty || active || touched) && error && <span className="form-group__error">{error}</span> }
  </div>
)

class AddAchForm extends Component {
  /**
   * Render the component view.
   */
  render() {
    return (
      <form onSubmit={ this.props.handleSubmit }>
        <div className="form-group">
          <label className="form-group__label" htmlFor="bankName">{this.props.whiteLabel.labels['Bank Nickname']}</label>
          <Field name="bankName" type="text" maxLength="50" component={ renderField } />
        </div>

        <div className="form-group">
          <label className="form-group__label" htmlFor="accountType">{this.props.whiteLabel.labels['Account Type']}</label>
          <Field name="accountType" type="text" component={ renderSelect }>
            <option value="">{this.props.whiteLabel.labels['Account Type']}</option>
            <option value="1">{this.props.whiteLabel.labels['Checking']}</option>
            <option value="2">{this.props.whiteLabel.labels['Savings']}</option>
          </Field>
        </div>

        <div className="form-group">
          <label className="form-group__label" htmlFor="name">{this.props.whiteLabel.labels['Full Name']}</label>
          <Field name="name" type="text" maxLength="20" component={ renderField } />
        </div>

        <div className="form-group">
          <label className="form-group__label" htmlFor="routingNumber">{this.props.whiteLabel.labels['Routing (ABA) Number']}&nbsp;&nbsp;
            <i data-tip data-for="RoutingNumTip" className="fas fa-info-circle"></i>
            </label>
            <ReactTooltip id="RoutingNumTip" type="info"><span> { this.props.whiteLabel.labels['RoutingNumTip'] } </span></ReactTooltip>
          <Field name="routingNumber" type="text" maxLength="9" component={ renderField } normalize={ onlyNumbers } />
        </div>

        <div className="form-group">
          <label className="form-group__label" htmlFor="accountNumber">{this.props.whiteLabel.labels['Bank Account Number']}&nbsp;&nbsp;
          <i data-tip data-for="AccountNumTip" className="fas fa-info-circle"></i>
          </label>
          <ReactTooltip id="AccountNumTip" type="info"><span> { this.props.whiteLabel.labels['AccountNumTip'] } </span></ReactTooltip>
          <Field name="accountNumber" type="text" maxLength="25" component={ renderField } />
        </div>

        <div className="form-group">
          <img className="routing-info-img" src={infoRoutingImg} alt="" />
          <div className="routing-info-in">
            <span className="routing-info-one">{this.props.whiteLabel.labels['ROUTING NUMBER']}</span>
            <span className="routing-info-two">{this.props.whiteLabel.labels['ACCOUNT NUMBER']}</span>
            <span className="routing-info-three">{this.props.whiteLabel.labels['CHECK NUMBER']}</span>
          </div>
        </div>

        <PayixButton className="button--full" type="submit" disabled={!this.props.valid}>
            {this.props.whiteLabel.labels['Save']}
        </PayixButton>
      </form>
    );
  }
}

AddAchForm = reduxForm({
  form: 'addAchForm',
  validate
})(AddAchForm)
 
export default AddAchForm;