import React, { Component } from 'react';

// Import components
import ReactPaginate from 'react-paginate';

// Import styles
import '../../styles/modules/widgets/paginator.css';

class Paginator extends Component {
  /**
   * Render the componen view.
   */
  render() {
    return (
      <ReactPaginate
        previousLabel={this.props.whiteLabel.labels['previous']}
        nextLabel={this.props.whiteLabel.labels['next']}
        breakLabel={ <a>...</a> }
        pageCount={ this.props.totalPages }
        forcePage={ this.props.forcePage }
        marginPagesDisplayed={ 2 }
        pageRangeDisplayed={ 5 }
        onPageChange={ this.props.onPageChange }
        containerClassName="paginator"
        pageClassName="paginator__page"
        pageLinkClassName="paginator__page-link"
        activeClassName="paginator__page--current"
        previousClassName="paginator__prev-control"
        previousLinkClassName="paginator__prev-control-link"
        nextClassName="paginator__next-control"
        nextLinkClassName="paginator__next-control-link" />
    );
  }
}

export default Paginator;