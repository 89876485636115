import React, { Component } from 'react';
import P from 'prop-types';

// Import components
import ReactModal from 'react-modal';

// Import styles
import '../../styles/modules/widgets/modal.css';

class Modal extends Component {
  handleClose = () => {
    this.props.onClose();
    
    if (this.props.onClosing) {
      this.props.onClosing();
    }
  }

  /**
   * Render a component view.
   */
  render() {
    const { className, modalContentClassName, children, ...rest } = this.props;

    return (
      <ReactModal
        isOpen={this.props.isOpen}
        appElement={document.getElementById('root')}
        className={`modal-container animated ${className}`}
        overlayClassName="modal__overlay"
        {...rest}>

        <div className={`modal ${modalContentClassName}`} style={{maxWidth: this.props.width}}>
          <button
            className="modal__close"
            type="button"
            onClick={this.handleClose}>
            <i className="fas fa-times" />
          </button>
      
          {children}
        </div>
      </ReactModal>
    );
  }
}

Modal.propTypes = {
  className: P.string
};

Modal.defaultProps = {
  className: 'bounceInDown',
  modalContentClassName: '',
  width: '500px'
};

export default Modal;
