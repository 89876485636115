import store from '../config/store';
import { findById } from '../helpers/app';

class LoanAccount {
  /**
   * Return the current selected loan account in the local storage.
   */
  getSelectedId() {
    return localStorage.getItem('selectedLoanAccount');
  }

  /**
   * Get all data for an specific loan account.
   * 
   * @param {*} id 
   */
  get(id) {
    if (id) {
      let loanAccounts = store.getState().app.loanAccounts;
      let selected = parseInt(this.getSelectedId(), 10);

      return findById(selected, loanAccounts);
    }

    return null;
  }

  /**
   * Set the selected loan account in the local storage.
   * 
   * @param {*} id 
   */
  setSelectedLoanAccount(id) {
    localStorage.setItem('selectedLoanAccount', id);
  }
}

export default LoanAccount;