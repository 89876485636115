export const SHOW_MESSAGE_BAR = 'SHOW_MESSAGE_BAR';
export const HIDE_MESSAGE_BAR = 'HIDE_MESSAGE_BAR';
export const MAKE_PAYMENT = 'MAKE_PAYMENT';
export const SCHEDULE_PAYMENT = 'SCHEDULE_PAYMENT';
export const SELECT_PAYMENT_METHOD = 'SELECT_PAYMENT_METHOD';
export const SET_PAYMENT_AMOUNT = 'SET_PAYMENT_AMOUNT';
export const SEND_PAYMENT_RECEIPT = 'SEND_PAYMENT_RECEIPT';
export const SHOW_LOADING = 'SHOW_LOADING';
export const HIDE_LOADING = 'HIDE_LOADING';
export const FETCH_RECEIPTS = 'FETCH_RECEIPTS';
export const SET_SELECTED_RECEIPT = 'SET_SELECTED_RECEIPT';
export const SET_NOTICE = 'SET_NOTICE';