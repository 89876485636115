import React, { Component } from 'react';

class ForgotPassword extends Component {
  /**
   * Render the component view.
   */
  render() { 
    return (
      <div>
        <div className="modal__header">
          <h3 className="modal-title common-title">
            {this.props.whiteLabel.labels['Forgot Password']}
          </h3>
        </div>

        <div className="modal__body">
          <div className="modal__body-content--pad35 text-center">
            <p className="modal-paragraph">
              {this.props.whiteLabel.labels['Forgot Password Text']}
            </p>

            {this.props.form}
          </div>
        </div>
      </div>
    );
  }
}
 
export default ForgotPassword;