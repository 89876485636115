import * as types from '../constants/actions/chat';

const defaultState = {
  loading: false,
  currentPage: null,
  totalPage: null,
  messages: []
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case types.FETCHING_MESSAGES :
      return {...state, loading: true}
    case types.FETCHING_MESSAGES_FINISHED :
      return {...state, loading: false}
    case types.SET_MESSAGES :
      let msgs = action.payload.messages;

      if (action.payload.append) {
        msgs = [...state.messages, ...msgs]
      }

      return {
        loading: false,
        currentPage: action.payload.currentPage,
        totalPage: action.payload.totalPage, 
        messages: msgs
      }
    case `${types.SEND_MESSAGE}_PENDING` :
      return {...state, loading: true}
    case `${types.SEND_MESSAGE}_FULFILLED` :
      //let messages = Object.assign([], state.messages);
      //messages.unshift(action.payload.data.result.data[0]);

      return {
        ...state,
        loading: false
        //messages
      }
    default :
      return state;
  }
};