import React, { Component } from 'react';
import { toUSD } from '../../helpers/formatters';
import PayixButton from '../../containers/payix-button';

// Import assets
import warningIcon from '../../assets/images/warning.png';

class EditScheduleAchTerms extends Component {
  /**
   * Render the component view.
   */
  render() {
    let { company } = this.props;
    let achDisclosure = '';
    if (this.props.isSchedule) {
      achDisclosure = this.props.disclaimer.borrowerRecurringAchDisclosure;
    } else {
      achDisclosure = this.props.disclaimer.borrowerOnetimeAchDisclosure;
    }
    return (
      <div>
        <div className="modal__header">
          <div className="modal__header-content--centered">
            <img src={ warningIcon } alt="" />
          </div>
        </div>

        <div className="modal__body">
          <div className="modal__body-content--pad35">
            <p className="ach-terms gothambook-font" dangerouslySetInnerHTML={{__html: achDisclosure}}></p>

            <div className="form-group form-group--row-layout">
              <p className="size-50per no-margin">{this.props.whiteLabel.labels['Payment Amount:']} <span className="gothammedium-font">{toUSD(this.props.amount)}</span></p>
              <p className="size-50per no-margin">{this.props.whiteLabel.labels['Bank Account:']} <span className="gothammedium-font">**{this.props.lastFour}</span></p>
            </div>

            <div className="form-group form-group--row-layout">
              <p className="size-50per no-margin">{this.props.whiteLabel.labels['Payment Start Date:']} <span className="gothammedium-font">{this.props.scheduleDate}</span></p>
            </div>

            <div className="form-group form-group--row-layout">
              <PayixButton className="size-50per margin-right-10" onClick={ this.props.onCancel }>
                  {this.props.whiteLabel.labels['Cancel']}
              </PayixButton>

              <PayixButton className="size-50per margin-right-10" onClick={ this.props.onSubmit }>
                  {this.props.whiteLabel.labels['Submit']}
              </PayixButton>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
 
export default EditScheduleAchTerms;