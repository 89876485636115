import React, { Component } from 'react';
import EditContactInformationForm from '../../settings/edit-contact-information-form';
import { requestUpdateContactInfo } from '../../../api/client';
import { INTERNAL_ERROR } from '../../../constants/errors';

class EditContactInformation extends Component {
  /**
    * Update the contact information.
    * 
    * @param values
    */
  handleSubmit = (values) => {
    this.props.showLoading();

    let data = {
      cifnumber: this.props.loanNumber,
      city: values.city,
      id: this.props.loanId,
      isBorrower: this.props.isBorrower,
      loanNumber: this.props.loanNumber,
      state: values.state,
      streetaddress1: values.streetAddress,
      streetaddress2: values.unitNumber,
      type: 1,
      zip: values.zipcode
    }

    requestUpdateContactInfo(data)
      .then((response) => {
        this.props.hideLoading();

        let data = response.data;

        if (data.status === 1 && data.statusCode === 200) {
          this.props.onSuccess(data.message);
        } else {
          this.props.onError(data.message);
        }
      })
      .catch(() => {
        this.props.hideLoading();
        this.props.onError(INTERNAL_ERROR);
      });
  }

  /**
   * Render the component view.
   */
  render() {
    let initialValues = {
      streetAddress: this.props.streetAddress,
      unitNumber: this.props.unitNumber,
      state: this.props.state,
      city: this.props.city,
      zipcode: this.props.zipcode   
    }

    return (
      <div>
        <div className="modal__header">
          <div className="modal__note-wrapper">
            <h3>{this.props.whiteLabel.labels['UPDATE YOUR ADDRESS']}</h3>
            <p>
              {this.props.whiteLabel.labels['Please change the fields below to change the billing address for your account.']}
            </p>
          </div>
        </div>

        <div className="modal__body">
          <EditContactInformationForm 
            initialValues={initialValues}
            states={this.props.states}
            onSubmit={this.handleSubmit}
            whiteLabel={this.props.whiteLabel} />
        </div>
      </div>
    )
  }
}
 
export default EditContactInformation;