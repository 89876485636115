import { getWhiteLabels } from '../api/white-label';


export function getWhiteLabelsAction(lang = 'en'){
    return function(dispatch) {
        dispatch({
            type: 'loading-config'
        });
        return getWhiteLabels(lang).then((xhr)=>{
            dispatch({
                type: 'loading-config-finish',
                payload: xhr.data.result.data
            });
        }).catch((ex)=>{
            dispatch({
                type: 'loading-config-error',
                error: ex
            });
            console.warn(ex);
        });
    };
    
}