import React, { Component } from 'react';
import { formatToSsn, removeDashes } from '../helpers/formatters';
import { onlyNumbers } from '../helpers/app';

// Import components
import { Field, reduxForm } from 'redux-form';
import Datefield,{DatefieldMin18} from './application/form/datefield';
import PayixButton from '../containers/payix-button';

const validate = (values, _parent) => {
  const errors = {}

  if (!values.loannumber) {
    errors.loannumber = _parent.whiteLabel.labels['This field is required!'];
  }

  if (!values.zipcode) {
    errors.zipcode = _parent.whiteLabel.labels['This field is required!'];
  }

  if (values.zipcode && values.zipcode.length > 7) {
    errors.zipcode = _parent.whiteLabel.labels['Postal code cannot exceed 7 characters.'];
  }

  return errors;
};

const renderField = ({input, label, type, htmlFor, maxLength, meta: { dirty, active, touched, error }}) => (
  <div className="form-group">
    <label className="form-group__label" htmlFor={ htmlFor }>
      { label }
    </label>
    <input className="form-group__input" id={htmlFor} {...input} maxLength={ maxLength } type={type} />
    { (dirty || active || touched) && error && <span className="form-group__error">{error}</span> }
  </div>
)

class LoanAccountLinkForm extends Component {
  /**
   * Render the SSN Or DBO Field.
   */
  renderField1 = () => {
    if(this.props.field1Type === 1){
      if (this.props.isSsn) {
        return (
          <Field
            name="ssn"
            type="text"
            htmlFor="txtSsn"
            component={renderField}
            label={this.props.whiteLabel.labels['Social Security Number']}
            maxLength="11"
            autoComplete="off"
            normalize={onlyNumbers}
            format={formatToSsn}
            parse={removeDashes}
          />
        );
      }else{
        return (
          <Field
            name="dob"
            type="text"
            htmlFor="txtDbo"
            component={DatefieldMin18}
            label={this.props.whiteLabel.labels['DOB']}
          />
        );
      }
    }else if(this.props.field1Type === 2){
      return (
        <Field 
          name="ssn4"
          type="text"
          htmlFor="ssn4"
          component={renderField}
          label={this.props.whiteLabel.labels['Social Security Number Four']}
          maxLength="4"
          autoComplete="off"
          normalize={onlyNumbers}
          parse={removeDashes}
        />
      );
    }else if(this.props.field1Type === 3){
      return (
        <Field 
          name="name" 
          type="text" 
          htmlFor="name" 
          component={ renderField } 
          label={this.props.whiteLabel.labels['First Name']} 
        />
      );
    }else if(this.props.field1Type === 4){
      return (
        <Field 
          name="phoneNo" 
          type="text" 
          htmlFor="phoneNo" 
          normalize={ onlyNumbers } 
          component={ renderField } 
          label={this.props.whiteLabel.labels['Phone Number']}
          parse={ removeDashes } 
        />
      );
    }else{
      if (this.props.isSsn) {
        return (
          <Field
            name="ssn"
            type="text"
            htmlFor="txtSsn"
            component={renderField}
            label={this.props.whiteLabel.labels['Social Security Number']}
            maxLength="11"
            autoComplete="off"
            normalize={onlyNumbers}
            format={formatToSsn}
            parse={removeDashes}
          />
        );
      }else{
        return (
          <Field
            name="dob"
            type="text"
            htmlFor="txtDbo"
            component={DatefieldMin18}
            label={this.props.whiteLabel.labels['DOB']}
          />
        );
      }
    }

    
  }
  
  renderField3 = () => {
    if(this.props.field3Type === 1){
      return (
        <Field 
          name="zipcode" 
          type="text" 
          htmlFor="zipcode" 
          maxLength="7"  
          component={ renderField } 
          label={this.props.whiteLabel.labels['Postal Code']} 
        />
      );
    }else if(this.props.field3Type === 2){
      return (
        <Field 
          name="phoneNo" 
          type="text" 
          htmlFor="phoneNo" 
          normalize={ onlyNumbers } 
          component={ renderField } 
          label={this.props.whiteLabel.labels['Phone Number']}
          parse={ removeDashes } 
        />
      );
    }else if(this.props.field3Type === 3){
      return (
        <Field 
          name="branchNo" 
          type="text" 
          htmlFor="branchNo" 
          normalize={ onlyNumbers } 
          component={ renderField } 
          label={this.props.whiteLabel.labels['Branch Number']}
          parse={ removeDashes } 
        />
      );
    }else{
      return (
        <Field 
          name="zipcode" 
          type="text" 
          htmlFor="zipcode" 
          maxLength="7" 
          component={ renderField } 
          label={this.props.whiteLabel.labels['Postal Code']} 
        />
      );
    }

  }




  /**
   * Render the component view.
   */
  render() {
    return (
      <div>
        <div className="modal__header">
          <div className="modal__note-wrapper">
            <h3>{this.props.whiteLabel.labels['LINK ACCOUNT']}</h3>
            <p>{this.props.whiteLabel.labels['Please enter the information below to access your account']}</p>
          </div>
        </div>

        <div className="modal__body">
          <form onSubmit={ this.props.handleSubmit }>

          {/* Field 1 */}
          {this.renderField1()}

            {/* Loan account */}
            <div className="margin-bottom-10">
              <Field 
                name="loannumber" 
                type="text" 
                htmlFor="loannumber" 
                component={ renderField } 
                label={this.props.whiteLabel.labels['Loan Number']} 
              />
              <small className="form-group__help">
                {typeof this.props.loanMessage !== 'undefined'?this.props.loanMessage:''}
              </small>
            </div>

            {/* Field 3 */}
            {this.renderField3()}
              

            <div className="margin-top-25">
              <PayixButton className="button--full" type="submit" disabled={!this.props.valid} >
              {this.props.whiteLabel.labels['LINK ACCOUNT']}
              </PayixButton>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

LoanAccountLinkForm = reduxForm({
  form: 'loanAccountLinkForm',
  destroyOnUnmount: false,
  validate
})(LoanAccountLinkForm)

export default LoanAccountLinkForm;