import React, { Component } from 'react';
import PayixButton from '../../../containers/payix-button';
import warningIcon from '../../../assets/images/warning.png';

class ResendEmailVerification extends Component {
  /**
   * Render the component view.
   */
  render() { 
    return (
      <div>
        <div className="modal__header">
          <div className="modal__header-content--centered">
            <img src={warningIcon} alt="" />

            <p className="modal-header-text">
            {this.props.whiteLabel.labels['Email Address is not verified!']} 
            </p>
          </div>
        </div>
        
        <div className="modal__body">
          <div className="modal__body-content--pad35 text-center">
            <p className="modal-paragraph">{this.props.whiteLabel.labels['Do you want to resend verification link?']}</p>

            <div className="form-group form-group--row-layout">
              <PayixButton className="size-50per margin-right-10" onClick={this.props.onCancel}>
                  {this.props.whiteLabel.labels['Cancel']}
              </PayixButton>

              <PayixButton className="size-50per margin-right-10" onClick={this.props.onResendLink}>
                  {this.props.whiteLabel.labels['Resend Link']}
              </PayixButton>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
 
export default ResendEmailVerification;