export const HOST = process.env.REACT_APP_ROOT_URL + process.env.REACT_APP_API_URL;

export const ENDPOINTS = {
    socialSignUp: "/users/web/socialsignup",
    verifyUser: "/users/web/verify-user",
    login: "/users/web/checksocialexistance",
    states: '/users/stateslist',
    verifyOTP: '/users/web/verify-otp',
    forgotPassword: '/users/web/forgot-password',
    getClientDetails: '/client/get-client-details',
    listLoan: '/users/list-loan-account',
    linkLoan: "/users/link-loan-account",
    notifyLender: '/users/notify-lander',
    checkSingUp: '/users/check-signup-details',
    getReceipts: '/payments/get-payment-receipts',
    deleteLoan: '/loan/delete-loan',
    addCard: '/payments/add-card',
    addAccount: '/ach/add',
    removeCard: '/payments/delete-card',
    removeAccount: '/ach/delete',
    listCards: '/payments/get-card-lists',
    resendOTP: '/users/resend-otp',
    changePassword: '/users/web/change-password',
    sendPaymentReceipt: "/payments/send-payment-receipts",
    makePayment: '/payments/make',
    privacyPolicy: '/users/list-loan-account',
    logout: '/users/signout',
    getMessage: '/chat/get-message',
    userDetails: '/users/web/details',
    editProfile: '/users/web/edit-profile',
    deleteEmail: '/users/web/delete-email',
    addEmail: '/users/add-email',
    makePrimary: '/users/make-primary',
    deletePhone: '/users/delete-phone',
    addPhone: '/users/add-phone',
    makePrimaryPhone: '/users/make-primary-phone',
    scheduleList: '/payments/schedule-list',
    paymentSchedule: '/payments/scheduled-payment',
    paymentEmailSchedule: '/payments/recurring-email',
    scheduleFrequency: '/payments/schedule-frequency',
    deleteSchedule: '/payments/delete-schedule',
    paymentFrequencies: '/client/payment-frequencies',
    resendLink: '/users/verify-email',
    transactionMargin: '/client/transaction-margin-info',
    sendMessage: "/chat/create-message",
    loanAccountImageUpload: "/users/web/upload-image",
    updateNotificationToken: '/users/update-web-notification-token',
    updateDetails: "/users/web/update-details",
    terms: '/users/terms-and-condition',
    checkTermsUpdate: '/users/web/check-terms-and-condition',
    termsAccpetUpdate: '/client/legal-accept-update',
    loanScheduledList: '/payments/schedule-list',
    scheduledList: '/payments/scheduled-payment',
    editSchedule: '/payments/edit-scheduled-payment',
    getBackGroundImage: '/users/web/get-background-image',
    listAllLoans: '/users/list-all-loan-accounts',
    getClientInfo: '/client/get-client-images',
    deleteAllScheduled: '/users/web/delete-all-schedule',
    checkValidCard: '/payments/card-validate',
    getConvenienceFee: '/payments/get-convenience-fees',
    capToBorrowerLogin: '/web/users/cap-to-borrower-login',
    getPaymentMessage: '/payments/get-payment-message',
    whiteLabelData: 'client/white-labels',
    changeLanguage: '/users/web/change-language',
    sendCodeByPhone: '/users/send-otp-code-call',
    loanStatementList: '/loan/get-statements',
    loanStatementPdf: '/loan/get-statement-pdf',
    getClientBorrowerMessages: '/client/borrower-messages',
    getConstantAiUrl: '/constant-ai-url',
};

export const DEVICE = {
    DEVICE_TYPE: 3,
    OS: 'web',
    ID: 3,
    TOKEN: 123
};

export const LOGIN_TYPE = {
    FB: 1,
    GOOGLE: 2,
    TRADITIONAL: 3
};
