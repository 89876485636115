import * as types from '../constants/actions/client';
import { showLoading, hideLoading, showMessageBar } from '../actions/app';
import { 
  fetchClientDetails, fetchPaymentFrequencies, fetchBackgroundImage, requestAllCards, 
  addCard as requestAddCard, addAch as requestAddAch, requestTransactionMargin, 
  getClientImages as requestClientImages } 
from '../api/client';

export function getClientDetails($loan_no) {
  return {
    type: types.GET_CLIENT_DETAILS,
    payload: fetchClientDetails($loan_no)
  }
}

export function getPaymentFrequencies() {
  return {
    type: types.GET_PAYMENT_FREQS,
    payload: fetchPaymentFrequencies()
  }
}

export function getBackgroundImage() {
  return {
    type: types.GET_BACKGROUND_IMAGE,
    payload: fetchBackgroundImage()
  }
}

export function getClientImages($loan_no) {
  return {
    type: types.SET_CLIENT_IMAGES,
    payload: requestClientImages($loan_no)
  }
}

export function getTransactionMargin() {
  return function(dispatch) {
    requestTransactionMargin()
      .then((response) => {
        let data = response.data;

        if (data.status === 1 && data.statusCode === 200) {
          dispatch({
            type: types.SET_TRANSACTION_MARGIN,
            payload: data.result.data
          });
        }
      })
      .catch((error) => {
        return Promise.reject(error);
      })
  }
}

/**
 * Create an action to fetch the payment cards.
 */
export function fetchPaymentCards() {
  return function(dispatch) {
    return requestAllCards()
      .then(function(response) {
        let data = response.data;

        if (data.status === 1 && data.statusCode === 200) {
          dispatch(setPaymentCards(response.data.result.savedCards)); 
        }

        return response;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
}

/**
 * Create an action to set the payment cards.
 */
export function setPaymentCards(payload) {
  return {
    type: types.SET_PAYMENT_CARDS,
    payload: payload
  }
}

/**
 * Create an action to add a card.
 * 
 * @param {*} payload 
 */
export function addCard(payload) {
  return function (dispatch) {
    dispatch(showLoading());

    return requestAddCard(payload)
      .then(function(response) {
        if (response.data.status === 1) {
          dispatch(showMessageBar({
            status: 'success',
            text: response.data.message
          }));
        } else if (response.data.status === 0) {
          dispatch(showMessageBar({status: 'error', text: response.data.message}));
        }

        dispatch(hideLoading());
        
        return response;
      })
      .catch(function() {
        dispatch(hideLoading());
      });
  }
}

/**
 * Create an action to add an ach account
 * 
 * @param {*} payload 
 */
export function addAch(payload) {
  return function (dispatch) {
    dispatch(showLoading());
    
    return requestAddAch(payload)
      .then(function(response) {
        if (response.data.status === 1) {
          dispatch(showMessageBar({
            status: 'success',
            text: response.data.message
          }));
        } else if (response.data.status === 0) {
          dispatch(showMessageBar({status: 'error', text: response.data.message}));
        }

        dispatch(hideLoading());
        
        return response;
      })
      .catch(function(error) {
        dispatch(hideLoading());
        return Promise.reject(error);
      });
  }
}
