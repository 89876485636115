import * as types from '../constants/actions/schedule';
import { 
  schedulePayment as requestSchedulepayment, 
  fetchScheduledPayments as requestFetchScheduledPayments,
} from '../api/payment';
import { showLoading, hideLoading, showMessageBar } from '../actions/app';
import { INTERNAL_ERROR } from '../constants/errors';

/**
 * Create an action to schedule a payment.
 * 
 * @param {*} payload 
 */
export function schedulePayment(payload) {
  return function (dispatch) {
    dispatch(showLoading());

    return requestSchedulepayment(payload)
      .then(function(response) {
        dispatch(hideLoading());
        return response;
      })
      .catch(function() {
        dispatch(hideLoading());
      });
  }
}

/**
 * Set one selected option on the scheduled payments filters.
 */
export function setOption(payload) {
  return {
    type: types.SET_OPTION,
    payload
  }
}

/**
 * Dispatch the action to store the scheduled payments.
 * 
 * @param {*} payload 
 */
export function setScheduledPayments(payload) {
  return {
    type: types.SET_SCHEDULED_PAYMENTS,
    payload
  }
}

/**
 * Dispath an action to get the scheduled payments for a specific loan account.
 * 
 * @param {*} payload 
 */
export function fetchScheduledPayments(payload) {
  let data = {
    limit: 1000,
    offset: 0,
    ...payload
  };

  return function(dispatch) {
    dispatch((showLoading()));
    
    requestFetchScheduledPayments(data)
      .then(function(response) {
        let responseData = response.data;

        if (responseData.statusCode === 200 && responseData.status === 1) {
          dispatch(setScheduledPayments(responseData.result.data));
        }

        dispatch(hideLoading());
      })
      .catch(function() {
        dispatch(hideLoading());
        dispatch(showMessageBar({
          status: 'error',
          text: INTERNAL_ERROR
        }));
      });
  }
}