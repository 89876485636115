import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Modal from './modal';

class Logo extends Component {
  /**
   * Render the component view.
   */
  render() { 
    return (
      <div className="app-header__logo-wrapper">
        <img className="app-header__logo" src={this.props.src} alt="logo" onClick={this.props.onOpenInfo} />
        <Modal 
          isOpen={this.props.isModalOpen}
          onClose={this.props.onClose}
          className="modal-container--logo"
          modalContentClassName="modal--logo"
          overlayClassName="modal__overlay modal__overlay--logo"
          closeTimeoutMS={0}
          onRequestClose={this.props.onClose}
          shouldCloseOnOverlayClick={true}>

          <div>
            <i className="fas fa-sort-up app-header__logo-info-arrow"></i>
            <div className="modal__body">
              <div className="modal__body-content--pad35 flex-block">
                <div className="app-header__logo-info-left">
                  <div className="app-header__logo-info-wrapper">
                    <img className="app-header__logo-info-img" src={this.props.src} alt="" />
                  </div>
                  <p className="app-header__logo-info-address">
                    {this.props.addressApptNum}<br />
                    {this.props.addressStreet}<br />
                    {`${this.props.addressCity}, ${this.props.addressState} ${this.props.addressZip}`}<br />
                    {this.props.phoneNumber}
                  </p>
                </div>
                <div className="app-header__logo-info-right">
                  <ul className="app-header__logo-info-links">
                    <li>
                      <a className="app-header__logo-info-link" href={this.props.website} target="_blank">
                        <i className="fa fa-globe" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li>
                      <Link to="/home" className="app-header__logo-info-link" onClick={this.props.onClose}>
                        <i className="far fa-comments"></i>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}
 
export default Logo;