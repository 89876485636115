import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { requestClientBorrowerMessages } from "../../api/client";

const style = {
  card: {
    padding: "5px 15px",
    fontSize: "0.8125rem",
    fontFamily: "'GothamMedium', sans-serif",
  },
};
class ClientBorrowerMessages extends Component {
  constructor() {
    super();
    this.state = {
      isLoading: true,
      clientBorrowerMessages: [],
      messageBoxTitle: null,
    };
  }
  componentDidMount() {
    requestClientBorrowerMessages()
      .then((response) => {
        let { message_box_title, messages } = response.data.result;
        this.setState({
          isLoading: false,
          clientBorrowerMessages: messages,
          messageBoxTitle: message_box_title,
        });
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
        });
      });
  }

  render() {
    return (
      <div>
        {this.state.isLoading ? (
          <div style={{ minHeight: 100, paddingTop: 50 }}>
            <center>Loading client messages...</center>
          </div>
        ) : (
          <React.Fragment>
            {this.state.clientBorrowerMessages.length > 0 && (
              <div>
                {this.state.messageBoxTitle && (
                  <h2 className="section-title">
                    {this.state.messageBoxTitle}
                  </h2>
                )}
                <div className="card" style={style.card}>
                  {this.state.clientBorrowerMessages.map((message, index) => {
                    return (
                      <div key={index}>
                        {message.message_title && (
                          <h5>{message.message_title}</h5>
                        )}
                        <p>{message.message_text}</p>
                        {index <
                          this.state.clientBorrowerMessages.length - 1 && (
                          <hr></hr>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </React.Fragment>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    cards: state.client.cards,
    whiteLabel: state.whiteLabel,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {},
    dispatch
  );
};

export default connect(mapStateToProps)(ClientBorrowerMessages);
