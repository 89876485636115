import { createStore, applyMiddleware, compose } from 'redux'
import { routerMiddleware } from 'react-router-redux'
import thunk from 'redux-thunk'
import createHistory from 'history/createBrowserHistory'
import rootReducer from '../reducers';
import promiseMiddleware from 'redux-promise-middleware';

export const history = createHistory()

const initialState = {}
let composeEnhancers = compose;

// Enable only redux devtools en development.
if (process.env.NODE_ENV === 'development') {
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
}

const middlewares = [
  thunk,
  promiseMiddleware(),
  routerMiddleware(history)
]

const store = createStore(
  rootReducer,
  initialState,
  composeEnhancers(applyMiddleware(...middlewares))
);

export default store
