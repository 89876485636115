import React, { Component } from 'react';
import { onlyNumbers } from '../helpers/app';

// Import components
import { Field, reduxForm } from 'redux-form';
import PayixButton from '../containers/payix-button';

const validate = (values, _parent) => {
  const errors = {};

  if (!values.bankName) {
    errors.bankName = _parent.whiteLabel.labels['This field is required!'];
  }

  if (!values.name) {
    errors.name = _parent.whiteLabel.labels['This field is required!'];
  }

  if (!values.routingNumber) {
    errors.routingNumber = _parent.whiteLabel.labels['This field is required!'];
  } else if (values.routingNumber.length < 9) {
    errors.routingNumber = _parent.whiteLabel.labels['minimum length should be 9 digits'];
  }

  if (!values.accountNumber) {
    errors.accountNumber = _parent.whiteLabel.labels['This field is required!'];
  } else if (values.accountNumber.length < 4) {
    errors.accountNumber = _parent.whiteLabel.labels['Account Number should be between 4 to 25 characters'];
  }

  if (!values.branchNumber) {
    errors.branchNumber = _parent.whiteLabel.labels['This field is required!'];
  } else if (values.branchNumber.length < 5) {
    errors.branchNumber = _parent.whiteLabel.labels['Branch Number should be 5 characters'];
  }

  if (!values.bankNumber) {
    errors.bankNumber = _parent.whiteLabel.labels['This field is required!'];
  } else if (values.bankNumber.length < 3) {
    errors.bankNumber = _parent.whiteLabel.labels['Branch Number should be 5 characters'];
  }

  return errors;
};

const renderField = ({input, type, name, maxLength, meta: { dirty, active, touched, error }}) => (
  <div>
    <input className="form-group__input" id={name} {...input} maxLength={ maxLength } type={type} />
    { (dirty || active || touched) && error && <span className="form-group__error">{error}</span> }
  </div>
)

class AddPadForm extends Component {
  /**
   * Render the component view.
   */
  render() {
    return (
      <form onSubmit={ this.props.handleSubmit }>
        <div className="form-group">
          <label className="form-group__label" htmlFor="bankName">{this.props.whiteLabel.labels['Bank Nickname']}</label>
          <Field name="bankName" type="text" maxLength="50" component={ renderField } />
        </div>

        <div className="form-group">
          <label className="form-group__label" htmlFor="name">{this.props.whiteLabel.labels['Full Name']}</label>
          <Field name="name" type="text" maxLength="20" component={ renderField } />
        </div>

        <div className="form-group">
          <label className="form-group__label" htmlFor="routingNumber">{this.props.whiteLabel.labels['Bank Number']}</label>
          <Field name="bankNumber" type="text" maxLength="3" component={ renderField } normalize={ onlyNumbers } />
        </div>

        <div className="form-group">
          <label className="form-group__label" htmlFor="accountNumber">{this.props.whiteLabel.labels['Branch Number']}</label>
          <Field name="branchNumber" type="text" minLength="5" maxLength="5" component={ renderField } />
        </div>

        <div className="form-group">
          <label className="form-group__label" htmlFor="accountNumber">{this.props.whiteLabel.labels['Bank Account Number']}</label>
          <Field name="accountNumber" type="text" maxLength="12" component={ renderField } />
        </div>

        <PayixButton className="button--full" type="submit" disabled={!this.props.valid}>
            {this.props.whiteLabel.labels['Save']}
        </PayixButton>
      </form>
    );
  }
}

AddPadForm = reduxForm({
  form: 'addPadForm',
  validate
})(AddPadForm)
 
export default AddPadForm;