import * as types from '../constants/actions/app';
import {
  sendReceipt as requestSendReceipt,
  getReceipts as requestGetReceipts,
  requestConvenienceFee
} from '../api/payment';
import { isOkHttpResponse } from '../helpers/app';

/**
 * Show the loading.
 */
export function showLoading() {
  return {
    type: types.SHOW_LOADING
  }
}

/**
 * Hide the loading. 
 */
export function hideLoading() {
  return {
    type: types.HIDE_LOADING
  }
}

/**
 * Set a new message to be displayed in the application.
 * 
 * @payload
 */
export function showMessageBar(payload) {
  return {
    type: types.SHOW_MESSAGE_BAR,
    payload
  };
}

/**
 * hide the message bar.
 */
export function hideMessageBar() {
  return {
    type: types.HIDE_MESSAGE_BAR,
  };
}

/**
 * Set the current convenciences fee.
 * 
 * @param {*} transactionFee 
 */
export function setConvenienceFee(transactionFee) {
  return {
    type: types.SELECT_PAYMENT_METHOD,
    payload: transactionFee
  }
}

/**
 * Get and save the conveniences fee for the specific payment method.
 */
export function setPaymentMethod(brand, loanId, paymentType, freqType) {
  return function (dispatch) {
    dispatch(showLoading());

    return requestConvenienceFee(loanId, brand, paymentType, freqType)
      .then(function(response) {
        let data = response.data;
        dispatch(hideLoading());

        if (data.status === 1 && isOkHttpResponse(data.statusCode)) {
          dispatch(setConvenienceFee(data.result ? data.result.transactionFee : 0));
        } else {
          dispatch(showMessageBar({
            status: 'error',
            text: 'Internal error.'
          }));
        }
      })
      .catch(function() {
        dispatch(hideLoading());
      });
  }
}

/**
 * Set the payment amount.
 */
export function setAmount(amount) {
  return {
    type: types.SET_PAYMENT_AMOUNT,
    amount
  }
}

export function sendReceipt(payload) {
  return function(dispatch) {
    dispatch(showLoading());

    return requestSendReceipt(payload)
      .then(function(response) {
        let data = response.data;
        dispatch(hideLoading());

        if (data.status === 1 && data.statusCode === 200) {
          dispatch(showMessageBar({
            status: 'success',
            text: data.message
          }));

          return true;
        } else {
          dispatch(showMessageBar({
            status: 'error',
            text: data.message
          }));
        }

        return false;
      })
      .catch(function() {
        dispatch(hideLoading());
      });
  }
}

export function fetchReceipts(payload) {
  return {
    type: types.FETCH_RECEIPTS,
    payload: requestGetReceipts(payload)
  }
}

export function setSelectedReceipt(payload) {
  return {
    type: types.SET_SELECTED_RECEIPT,
    payload
  }
}
