import React, { Component } from 'react';
import { dateWithFormatOrNa } from '../../helpers/formatters';

class PaymentMethodCard extends Component {
  renderExpiryDate() {
    let date = dateWithFormatOrNa(this.props.expiryDate.date);

    return (
      <div className="line-height-15">
        <span className="payment-method__data">{this.props.whiteLabel.labels['Expiry']}</span>
        <span>{date}</span>
      </div>
    )
  }

  renderRoutingNumber() {
    return (
      <div className="line-height-15">
        <span className="payment-method__data">{this.props.whiteLabel.labels['Routing #']}</span>
        <span>{this.props.routingNumber}</span>
      </div>
    )
  }

  renderBankNumber() {
    return (
      <div className="line-height-15">
        <span className="payment-method__data">{this.props.whiteLabel.labels['Bank #']}</span>
        <span>{this.props.bankNumber}</span>
      </div>
    )
  }

  renderAchType() {
    let achTypeCaption;
    if(this.props.paymentType === 2) {
      achTypeCaption = this.props.accountType === 1 ? this.props.whiteLabel.labels['(Checking)'] : this.props.whiteLabel.labels['(Saving)'];
    }
    return (
     <span>{achTypeCaption}</span>
    )
  }

  /**
   * Render the component view.
   */
  render() { 
    return (
      <div className="payment-method-card">
        <div className="payment-method">
          <div className="flex-block flex-block--space-between">
            <span>
              <img className="payment-method__img" src={this.props.logo} alt="" />{this.props.franchise}
            </span>
            {this.props.options}
          </div>
          <div>
            <p className="payment-method__number">
              XXXXXX <span className="payment-method__last-four-number">{this.props.lastFour}</span>
              {this.renderAchType()}
            </p>
            <div className="flex-block flex-block--space-between">
              <div className="line-height-15">
                <span className="payment-method__data">{this.props.whiteLabel.labels['Account holder Name']}</span>
                <span> {this.props.name}</span>
              </div>
              {this.props.paymentType === 1 
                ? this.renderExpiryDate() 
                : this.props.paymentType === 2 ? this.renderRoutingNumber() : this.renderBankNumber()
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
}
 
export default PaymentMethodCard;