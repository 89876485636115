import React, { Component } from 'react';
import moment from 'moment';
import PayixButton from '../../containers/payix-button';
import successImg from '../../assets/images/checked.png';
import { isEmail } from 'validator';

class EmailReceiptSendModal extends Component {
  constructor(props) {
      super(props);
      this.state = {
          new_email: '',
          error_email: false,
          error_email_message: ''
      };
  }
  /**
   * Handler for on change event on the new_email field.
   * 
   * @param event
   */
  handleEmail = (event) => {
      if (!isEmail(event.target.value) && event.target.value != '') {
          this.setState({error_email_message: 'The email address is invalid', error_email: true});
      } else {
          this.setState({new_email: event.target.value, error_email_message: '', error_email: false});
      }
      
  }
  /**
   * Render the component view.
   */
  render() {
    return (
      <div>
        <button className="modal__close" type="button" onClick={this.props.onCancel}><i className="fas fa-times"></i></button>
        <div className="modal__header">
          <div className="modal__header-content--centered">
            <div className="modal-header-text">
                {this.props.whiteLabel.labels['Payment receipts will be sent']}:<span className="block gothammedium-font">{this.props.email}</span>
                <br></br>
                {this.props.whiteLabel.labels['If you would like your payment receipts emailed']}
                <input 
                className="form-group__input" 
                type="text" 
                maxLength="50"
                onChange={this.handleEmail} />
                {(this.state.error_email) ? <span className="color-red">{this.state.error_email_message}</span> : ''}
            </div>
          </div>
        </div>
        
        <div className="modal__body d-flex justify-content-center">
            <div className="congratulations-modal__body w-100">
                <PayixButton className="button--full" onClick={this.props.onCancel}>
                    {this.props.whiteLabel.labels['Cancel']}
                </PayixButton>
            </div>
            <div className="congratulations-modal__body w-100">
                <PayixButton className="button--full" onClick={this.props.onNext(this.state.new_email)} disabled={this.state.error_email}>
                    {this.props.whiteLabel.labels['Next']}
                </PayixButton>
            </div>
        </div>
      </div>
    );
  }
}

export default EmailReceiptSendModal;
