import React from 'react';

export default function UploadPhotoInput({ label, onChange }) {
  return (
    <label className="margin-bottom-10" htmlFor="file-input">
      <input id="file-input" className="file-input--hidden" type="file" accept=".jpg, .jpeg, .png" onChange={onChange} />
      <div className="file-input__upload">
        <div className="file-input__icon">
          <i className="fas fa-upload" />
        </div>
        <p className="file-input__upload-text">
          {label}
        </p>
      </div>
    </label>
  )
}
