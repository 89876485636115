import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PayixButton from "../containers/payix-button";
import Modal from "./application/modal";
import { userLogOut } from "../actions/auth";
import { setShowBkUdfPopup } from "../actions/loan-account";

class UdfDisclosureModal extends Component {
  constructor() {
    super();
    this.onClose = this.onClose.bind(this);
    this.onAccept = this.onAccept.bind(this);
  }
  onClose() {
    this.props.userLogOut();
  }
  onAccept() {
    this.props.setShowBkUdfPopup(false);
    sessionStorage.acceptedBkUdfPopup = true;
  }
  render() {
    return (
      <Modal isOpen={this.props.showBkUdfPopup} onClose={this.onClose}>
        <div>
          <div className="modal__header">
            <div className="modal__header-content--centered">
              {/* UDF Disclouser Modal */}
            </div>
          </div>

          <div className="modal__body">
            <div className="modal__body-content--pad35">
              <p className="modal-paragraph text-left">
                {this.props.bkUdfMessage}
              </p>
              <div className="form-group form-group--row-layout">
                <PayixButton
                  className="size-50per margin-right-10"
                  onClick={this.onClose}
                >
                  {this.props.whiteLabel.labels["Cancel"] != undefined
                    ? this.props.whiteLabel.labels["Cancel"]
                    : "Cancel"}
                </PayixButton>

                <PayixButton
                  className="size-50per margin-right-10"
                  onClick={this.onAccept}
                >
                  {this.props.whiteLabel.labels["Accept"] != undefined
                    ? this.props.whiteLabel.labels["Accept"]
                    : "Accept"}
                </PayixButton>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

UdfDisclosureModal.defaultProps = {
  className: "",
  type: "modal",
};

const mapStateToProps = (state) => {
  return {
    color: state.client.data.colorCode,
    bkUdfMessage: state.client.data.bkUdfMessage,
    showBkUdfPopup: state.app.showBkUdfPopup,
    whiteLabel: state.whiteLabel,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      userLogOut,
      setShowBkUdfPopup,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(UdfDisclosureModal);
