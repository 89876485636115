import React, { Component } from 'react';
import AddEmailForm from '../../settings/add-email-form';
import { requestAddEmail } from '../../../api/client';
import { INTERNAL_ERROR } from '../../../constants/errors';

class AddEmail extends Component {
  addEmail = (values) => {
    this.props.showLoading();

    let data = {}

    //This is the process of the addition of the email address on NLS
    data = {
        id: this.props.loanId,
        email: values.email
    }

    requestAddEmail(data)
      .then((response) => {
        this.props.hideLoading();

        let data = response.data;

        if (data.status === 1 && data.statusCode === 200) {
            this.props.onSuccess();
        } else {
            this.props.onError(data.message);
        }
      })
      .catch(() => {
        this.props.hideLoading();
        this.props.onError(INTERNAL_ERROR);
      });
  }

  /**
   * Render the component view.
   */
  render() { 
    return (
      <div>
        <div className="modal__header">
          <h3 className="modal-title">{this.props.whiteLabel.labels['Add Email Address']}</h3>
        </div>

        <div className="modal__body">
          <div className="modal__body-content--pad35 text-center">
            <AddEmailForm onSubmit={this.addEmail}
            whiteLabel={this.props.whiteLabel}/>
          </div>
        </div>
      </div>
    )
  }
}
 
export default AddEmail;