import React, { Component } from 'react';
import Datasort from 'react-data-sort';
import { HOST, ENDPOINTS } from '../../constants/api';
import { Application } from '../../core/app';

class StatementList extends Component {

  /** 
   * If statement request is successful, we open it in a new tab
   * Otherwise, it's not available and we will display an error.
   */
  checkStatementExist = (documentId) => {
    let url = `${HOST}/loan/get-statement-pdf?documentId=${documentId}&loanId=${this.props.selectedLoanId}&accessToken=${localStorage.accessToken}`;

    Promise.all([
        window.axios.get(url)
      ])
      .then((res) => {
        if (res[0].data.status == undefined) {
          window.open(url, "_blank");
        } else {
          Application.showErrorMessage(res[0].data.message);
        }
      })
      .catch(() => {
        Application.showInternalError();
      });
  };

  /**
   * Render the component view.
   */
  render() {
    let url = this.props.url;

    return (
      <div className="statement-body">
        {this.props.tableData.length == 0 && <center>{this.props.whiteLabel.labels['No Records found']}</center>}
        {this.props.tableData.length != 0 && <Datasort
          data={this.props.tableData}
          paginate
          render={({ 
            data,
            setSortBy,
            sortBy,
            direction,
            activePage,
            toggleDirection,
            goToPage,
            nextPage,
            prevPage,
            pages
           }) => {
            return <div className="table-responsive-lg">
              <table className="table table-bordered table-hover">
                <thead className="thead-light">
                  <tr>
                    <th className="text-left">{this.props.whiteLabel.labels['Statement Date']}</th>
                    <th className="text-left">{this.props.whiteLabel.labels['Date Paid']}</th>
                    <th className="text-left">{this.props.whiteLabel.labels['Past Due Balance']}</th>
                    <th className="text-left">{this.props.whiteLabel.labels['Current Balance']}</th>
                    <th className="text-left">{this.props.whiteLabel.labels['Current Payoff']}</th>
                    <th className="text-left">{this.props.whiteLabel.labels['View Statements']}</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map(({
                    statement_date,
                    statement_due_date,
                    total_past_due_balance,
                    total_current_due_balance,
                    current_payoff_balance,
                    DocumentID
                  }) => (
                      <tr key={DocumentID}>
                        <td>{statement_date}</td>
                        <td>{statement_due_date}</td>
                        <td>{total_past_due_balance}</td>
                        <td>{total_current_due_balance}</td>
                        <td>{current_payoff_balance}</td>
                        <td><a href="#" onClick={() => this.checkStatementExist(DocumentID)} className="link-no-style">{this.props.whiteLabel.labels['View'] + " " + DocumentID}</a></td>
                      </tr>
                    ))}
                </tbody>
              </table>
              <Flex style={{justifyContent: 'space-between'}}>
              <GoToPage labels={this.props.whiteLabel.labels} goToPage={goToPage} pages={pages} />
              <PageIndicator pages={pages} activePage={activePage} />
              <Navigation
                activePage={activePage}
                goToPage={goToPage}
                nextPage={nextPage}
                prevPage={prevPage}
                pages={pages}
              />
              
            </Flex>
            </div>
          }}
        />}
      </div>
    );
  }
}

function Flex({ children, style }) {
  return <div style={{ display: "flex", ...style }}>{children}</div>;
}

function GoToPage({ goToPage, pages, labels}) {
  const options = []
  for(let i = 0; i < pages; i++) {
    options.push(<option value={i}>{i + 1}</option>)
  }
  return <div>{labels['Go to page']} <select onChange={e => goToPage(parseInt(e.target.value))}>{options}</select></div>
}

function Navigation({ activePage, goToPage, nextPage, prevPage, pages }) {
  return (
    <Flex>
      <button disabled={activePage === 0} onClick={() => goToPage(0)}>
        {"<<"}
      </button>
      <button disabled={activePage === 0} onClick={prevPage}>
        {"<"}
      </button>

      <button disabled={activePage === pages - 1} onClick={nextPage}>
        {">"}
      </button>
      <button
        disabled={activePage === pages - 1}
        onClick={() => goToPage(pages - 1)}
      >
        {">>"}
      </button>
    </Flex>
  );
}

function PageIndicator ({pages, activePage}) {
  return <div>
    <b>{activePage + 1}</b> / {pages}
  </div>
} 

export default StatementList;