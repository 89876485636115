import React from 'react';
import { Field, reduxForm } from 'redux-form';
import '../../styles/modules/form.css';
import PayixButton from '../../containers/payix-button';
import PasswordShowHide from "../../components/password-show-hide";

const validate = (values, _parent) => {
  const errors = {}
  if (!values.user) {
    errors.user = _parent.whiteLabel.labels['This field is required'];
  }
  if (!values.password) {
    errors.password = _parent.whiteLabel.labels['This field is required'];
  }
  return errors;
};

const renderField = ({input, label, type, htmlFor, autoComplete, meta: { touched, error }}) => (
  <div className="form-group">
    <label htmlFor={htmlFor} className="form-group__label">{label}</label>
    <input className="form-group__input" id={htmlFor} {...input} type={type} autoComplete={autoComplete} />
    {touched && error && <span className="form-group__error">{error}</span>}
  </div>
);

const renderInputPass = ({
  input,
  label,
  type,
  htmlFor,
  maxLength,
  autoComplete,
  meta: { touched, error },
  ...rest
}) => (
  <div className="form-group">
    <label htmlFor={htmlFor} className="form-group__label">
      {label}
    </label>
    <PasswordShowHide
      className="form-group__input"
      id={htmlFor}
      input={input}
      rest={rest}
      type={type}
      autoComplete={autoComplete}
    />
    {touched && error && <span className="form-group__error">{error}</span>}
  </div>
);

let SignInForm = props => {
  const { handleSubmit, valid } = props;

  return (
    <form onSubmit={handleSubmit}>
      <div className="form-group">
        <Field name="user" type="text" htmlFor="txtUser" autoComplete="username"
          component={renderField} label={props.whiteLabel.labels["Email Address/ Mobile Number"]} />
      </div>
      <Field
          name="password"
          type="password"
          htmlFor="txtPassword"
          component={renderInputPass}
          className="form-group__input" 
          label={props.whiteLabel.labels["Password"]}
        />
      <div className="form-group">
        <PayixButton className="button--full" type="submit" disabled={!valid}>
            {props.whiteLabel.labels['Sign In']}
        </PayixButton>
      </div>
    </form>
  );
};

SignInForm = reduxForm({
  form: 'signin',
  validate
})(SignInForm)

export default SignInForm;
