import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import { reducer as form } from 'redux-form';
import { LOG_OUT } from '../constants/actions/auth';

// Import reducers
import app from './app';
import auth from './auth';
import client from './client';
import chat from './chat';
import schedule from './schedule';
import whiteLabel from './white-label';
import statements from './statements';

let reducers = { app, form, auth, client, chat, schedule, whiteLabel, statements};

const appReducer = combineReducers({...reducers, routing: routerReducer});

const rootReducer = (state, action) => {
  if (action.type === LOG_OUT) {
    state = undefined
  }

  return appReducer(state, action)
}

export default rootReducer;
