import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { PAYMENT_TYPE_CARD, PAYMENT_TYPE_ACH } from '../../constants/app';
import bankImg from '../../assets/images/bank.png';
import { requestDeleteCard } from '../../api/client';
import { INTERNAL_ERROR } from '../../constants/errors';
import { findById } from '../../helpers/app';

// Import actions
import { showLoading, hideLoading, showMessageBar } from '../../actions/app';
import { addCard, addAch, fetchPaymentCards } from '../../actions/client';

// Import components
import AddPaymentMethodModal from '../../components/add-payment-method-modal';
import Dropdown from '../../components/application/dropdown/index';
import DropdownItem from '../../components/application/dropdown/item';
import Modal from '../../components/application/modal';
import PaymentMethodCard from './payment-method-card';
import RemoveCardConfirmation from '../../components/application/modals/remove-card-confirmation';

class PaymentMethods extends Component {
  state = {
    isModalOpen: false,
    modal: null
  }

  selectedPaymentMethod = null;

  componentDidMount() {
    this.fetchInitialData();
  }

  /**
   * Fetch the initial data.
   */
  fetchInitialData() {
    this.props.fetchPaymentCards()
  }

  /**
   * Close the modal opened.
   */
  closeModal = () => {
    this.setState({
      isModalOpen: false,
      modal: null
    });
  }

  /**
   * Display the add payment method modal.
   */
  showAddPaymentMethodModal = () => {
    this.setState({
      isModalOpen: true,
      modal: 'ADD_PAYMENT_METHOD'
    });
  }

  /**
   * Display the remove payment method modal for confirmation.
   */
  showDeletePaymentMethodModal = (id) => {
    this.selectedPaymentMethod = findById(id, this.props.cards);

    this.setState({
      isModalOpen: true,
      modal: 'REMOVE_CARD'
    });
  }

  /**
   * Close the modal to add a payment method and reload the payment methods.
   */
  handleAddPaymentMethodSuccess = () => {
    this.closeModal();
    this.fetchInitialData();
  }

  /**
   * Handle the event that delete a payment method
   */
  handleDeletePaymentMethod = () => {
    this.props.showLoading();

    requestDeleteCard(this.selectedPaymentMethod.id, this.selectedPaymentMethod.paymentType)
      .then((response) => {
        let data = response.data;

        this.closeModal();
        this.props.hideLoading();

        if (data.status === 1 && data.statusCode === 200) {
          this.props.showMessageBar({
            status: 'success',
            text: data.message
          });
          this.fetchInitialData();
        } else {
          this.props.showMessageBar({
            status: 'error',
            text: data.message
          });
        }

        this.selectedPaymentMethod = null;
      })
      .catch(() => {
        this.props.showMessageBar({
          status: 'error',
          text: INTERNAL_ERROR
        });
        this.closeModal();
        this.props.hideLoading();
        this.selectedPaymentMethod = null;
      })
  }

  /**
   * Render the options that appear on each card.
   */
  renderCardOptions(id, isBorrowerDeletable) {
    if (!isBorrowerDeletable) {
      return (
        <div>
          <div style={{ right: '10px' }} className="text-center">
          {this.props.whiteLabel.labels['Call to Edit']}
            </div>
        </div>
      )
    }
    return (
      <div>
        <Dropdown alignToRight={true} className="options-dropdown" handler={() =>
          <i className="fas fa-ellipsis-v icon--20px options-dropdown__handler"></i>
        }>
          <DropdownItem className="options-dropdown__item" value={id} onClick={this.showDeletePaymentMethodModal}>
          {this.props.whiteLabel.labels['Remove Account']}
          </DropdownItem>
        </Dropdown>
      </div>
    )
  }

  /**
   * Render a modal component.
   */
  renderModal() {
    return (
      <Modal
        isOpen={this.state.isModalOpen}
        onClose={this.closeModal}>
        {this.renderModalContent()}
      </Modal>
    );
  }

  /**
   * Render the indicate content on the modal opened.
   */
  renderModalContent() {
    switch (this.state.modal) {
      case 'ADD_PAYMENT_METHOD':
        return <AddPaymentMethodModal
          onSuccess={this.handleAddPaymentMethodSuccess} />
      case 'REMOVE_CARD':
        return <RemoveCardConfirmation
          hasScheduledPayments={this.selectedPaymentMethod.isSchedule}
          onCancel={this.closeModal}
          onDelete={this.handleDeletePaymentMethod}
          whiteLabel={this.props.whiteLabel} />
      default:
        return null;
    }
  }

  /**
   * Render the component view.
   */
  render() {
    let { cards } = this.props;

    return (
      <React.Fragment>
        <h2 className="settings-card__title">{this.props.whiteLabel.labels['Payment Options']}</h2>
        <hr className="hr" />

        <div className="payment-methods">
          {cards.map((card, index) =>
            <div className="payment-methods__item" key={index + 1}>
              <PaymentMethodCard
                paymentType={card.paymentType}
                logo={card.paymentType === PAYMENT_TYPE_CARD ? card.cardImage : bankImg}
                franchise={card.paymentType === PAYMENT_TYPE_CARD ? '' : card.bankName}
                lastFour={card.lastFour}
                name={card.paymentType === PAYMENT_TYPE_CARD ? card.name : card.paymentType === PAYMENT_TYPE_ACH ? card.nameOnAch : card.nameOnPad}
                routingNumber={card.bankRoutingNumber}
                bankNumber={card.bankNumber}
                expiryDate={card.cardExpDate}
                accountType={card.accountType}
                whiteLabel={this.props.whiteLabel}
                options={
                  this.renderCardOptions(card.id, card.isBorrowerDeletable)
                } />
            </div>
          )}

          {/**
            ** Only the button to add a new payment method if there are
            ** less than 5 is displayed. 
            **/}
          {(cards.length < 5)
            && (
              <div className="payment-methods__item">
                <div className="payment-method-card" onClick={this.showAddPaymentMethodModal}>
                  <div className="add-payment-method">
                    <i className="far fa-check-circle add-payment-method--icon"></i>
                    <p>{this.props.whiteLabel.labels['Add New Payment Method']}</p>
                  </div>
                </div>
              </div>
            )
          }
        </div>

        {this.renderModal()}
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    cards: state.client.cards,
    whiteLabel: state.whiteLabel
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    addCard, addAch, fetchPaymentCards, showLoading, hideLoading, showMessageBar
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentMethods);