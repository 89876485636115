import React, { Component } from 'react';

// Import assets
import infoIcon from '../assets/images/info.png';

// Import components.
import PayixButton from '../containers/payix-button';

class LinkingLoanAccountNotice extends Component {
  /**
   * Render a component view.
   */
  render() { 
    return (
      <div>
        <div className="modal__header">
          <div className="modal__header-content--centered">
            <img src={ infoIcon } alt="" />
          </div>
        </div>

        <div className="modal__body">
          <div className="modal__body-content--pad35 text-center">
            <p className="modal-paragraph">
              {this.props.whiteLabel.labels['Linking Account Issue confirmation message line1']}
              <br />
              <span className="gothammedium-font">{this.props.whiteLabel.labels['Linking Account Issue confirmation message line2']}</span>
            </p>

            <div className="form-group">
              <PayixButton className="button--full" onClick={ this.props.onBackToHome }>
                  {this.props.whiteLabel.labels['Back to Home']}
              </PayixButton>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
 
export default LinkingLoanAccountNotice;