import React, { Component } from 'react';

// Import styles
import '../../../styles/modules/widgets/drop-down-menu/index.css';

class Index extends Component {
  /**
   * Render the component view.
   */
  render() {
    // Append or remove classes to display or close the dropdown.
    let dropdownCss = 'dropdown-menu';

    if (this.props.side === 'right') {
      dropdownCss += ' dropdown-menu--right';
    }

    if (this.props.isDisplayed) {
      dropdownCss += ' dropdown-menu--open';
    }

    if (this.props.className) {
      dropdownCss += ' ' + this.props.className;
    }

    return (
      <ul className={ dropdownCss }>
        { this.props.children }
      </ul>
    );
  }
}
 
export default Index;