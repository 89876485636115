import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";

import LoanAccountService from "../services/loan-account";
import LoanAccountHeader from "../components/application/loan-account/header";
import {
  linkLoanAccount,
  setSelectedLoanAccount,
} from "../actions/loan-account";
import { showLoading, hideLoading, showMessageBar } from "../actions/app";
import { fetchLoanAccounts } from "../actions/loan-account";
import { requestConstantAiUrl } from "../api/client";
class Servicing extends Component {
  constructor() {
    super();
    this.state = {
      isLoading: false,
      ConstantAiUrl: "",
      redirect: false,
    };
  }

  /**
   * Listner for actions dispatched from ConstantAI iFrame
   */
  setupEventListner = () => {
    window.addEventListener("message", (event) => {
      console.log('Getting postMessage: ', event);
      const origin = event.origin;
      const { request = false } = event.data;
      
      // if request object not found or the event is not comming from ConstantAI then leave it
      if(!request || origin !== this.props.client.constantAiOrigin){
        return;
      }

      const { action = false, location = false } = request;
      if(action === "goto" && location === "makePayment"){
        this.setState({
          redirect: '/home'
        })
      }
    });
  };

  getIframeUrl = (params) => {
    requestConstantAiUrl(params)
      .then((response) => {
        this.setState({
          isLoading: false,
          ConstantAiUrl: response.data.data || "",
        });
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
        });
      });
  };

  /**
   * Handle the event when user change the selected loan account.
   */
  handleChangeLoanAccount = (value) => {
    this.props.setSelectedLoanAccount(value);
  };
  /**
   * Render the loan account header.
   */
  renderLoanAccountHeader() {
    let { loanAccounts, selectedLoanAccount } = this.props;

    if (loanAccounts === null || selectedLoanAccount === null) {
      return null;
    }

    return (
      <div style={{ margin: "5px 43px", width: "350px" }}>
        <LoanAccountHeader
          whiteLabel={this.props.whiteLabel}
          loanAccounts={loanAccounts}
          loanNo={selectedLoanAccount.loanNo}
          hideLinkButton={true}
          onChangeLoanAccount={this.handleChangeLoanAccount}
        />
      </div>
    );
  }

  componentDidMount() {
    this.props.showLoading();
    Promise.all([this.props.fetchLoanAccounts()])
      .then(() => {
        const loanAccountService = new LoanAccountService();
        const selectedLoanAccountId = loanAccountService.getSelectedId();
        const loan = loanAccountService.get(selectedLoanAccountId);
        this.getIframeUrl({
          loan_id: loan.id,
        });
        this.props.hideLoading();
        this.setupEventListner();
      })
      .catch(() => {
        this.props.hideLoading();
      });
  }
  render() {
    return (
      <React.Fragment>
        {this.renderLoanAccountHeader()}
        <iframe
          style={{ width: "100%", minHeight: "750px" }}
          src={this.state.ConstantAiUrl}
          id="ConstantAIFrame"
          onLoad={() => {
            const iFrame = document.getElementById("ConstantAIFrame");
            iFrame.height = window.innerHeight - 50;
          }}
          frameBorder={"false"}
        ></iframe>
        {this.state.redirect && (
          <Redirect to={this.state.redirect} />
        )}
      </React.Fragment>
    );
  }
}

const loanAccountService = new LoanAccountService();

const mapStateToProps = (state) => {
  return {
    client: state.client.data,
    loanAccounts: state.app.loanAccounts,
    selectedLoanAccount: loanAccountService.get(state.app.selectedLoanAccount),
    whiteLabel: state.whiteLabel,
    loading: state.app.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      fetchLoanAccounts,
      hideLoading,
      linkLoanAccount,
      setSelectedLoanAccount,
      showLoading,
      showMessageBar,
    },
    dispatch
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Servicing)
);
