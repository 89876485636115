export const LINK_LOAN_ACCOUNT = 'LINK_LOAN_ACCOUNT';
export const NOTIFY_LOAN_ACCOUNT_LINKING = 'NOTIFY_LOAN_ACCOUNT_LINKING';
export const FETCH_LOAN_ACCOUNTS = 'FETCH_LOAN_ACCOUNTS';
export const SET_LOAN_ACCOUNTS = 'SET_LOAN_ACCOUNTS';
export const SET_SELECTED_LOAN_ACCOUNT = 'SET_SELECTED_LOAN_ACCOUNT';
export const SELECT_LOAN_ACCOUNT = 'SELECT_LOAN_ACCOUNT';
export const DELETE_LOAN_ACCOUNT = 'DELETE_LOAN_ACCOUNT';
export const SET_SHOW_BK_UDF_POPUP = 'SET_SHOW_BK_UDF_POPUP';
export const SET_STATEMENT_LIST = 'SET_STATEMENT_LIST';
export const LOADING_STATEMENT_LIST = 'LOADING_STATEMENT_LIST';
