import React, { Component } from 'react';
import PayixButton from '../../../containers/payix-button';

class TermsConditions extends Component {
  /**
   * Render the component view.
   */
  render() { 
    return (
      <div>
        <div className="modal__header">
          <h3 className="modal-title common-title">{this.props.whiteLabel.labels['Terms & Conditions']}</h3>
        </div>

        <div className="modal__body terms-conditions">
          <div className="modal__body-content--pad35">
            <p className="terms-conditions__content" dangerouslySetInnerHTML={{__html: this.props.terms}}></p>

            <div className="form-group flex-drow flex-center">
              <PayixButton className="size-50per margin-right-10" onClick={this.props.onClose}>
              {this.props.whiteLabel.labels['Close']}
              </PayixButton>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
 
export default TermsConditions;