import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Route, withRouter } from 'react-router-dom';
import { reset } from 'redux-form';
import { changePassword, changeLanguage } from '../api/auth';
import { rsaEncrypt } from '../helpers/auth';

// Import actions
import { showLoading, hideLoading, showMessageBar } from '../actions/app';
import { getWhiteLabelsAction } from '../actions/white-label';

// Import components
import ChangePasswordForm from '../components/settings/change-password-form';
import ChangeLanguageForm from '../components/settings/change-language-form';
import ContactInformation from '../components/settings/contact-information';
import Menu from '../components/settings/menu';
import PaymentMethods from '../components/settings/payment-methods';

// Import styles
import '../styles/modules/settings/index.css';

class Settings extends Component {
  componentDidMount() {
    let url = this.props.match.url;
    let currentPath = this.props.location.pathname;

    if (currentPath === url) {
      this.props.history.replace(`${url}/payment-option`);
    }
  }

  /**
   * Handle the submit event for change the password.
   */
  changePassword = (values) => {
    this.props.showLoading();

    changePassword({
      oldPassword: rsaEncrypt(values.oldPassword),
      password: rsaEncrypt(values.newPassword)
    }).then((response) => {
      let data = response.data;
      this.props.hideLoading();

      if (data.status === 1 && data.statusCode === 200) {
        this.props.showMessageBar({status: 'success', text: data.message});
      }

      if (data.status === 0) {
        this.props.showMessageBar({status: 'error', text: data.message});
      }

      this.props.reset('changePasswordForm');
    }).catch(() => {
      this.props.hideLoading();
    });
  }

  /**
   * Handle the submit event for change the language.
   */
  changeLanguage = (values) => {
    this.props.showLoading();
    changeLanguage({
      language_code: values.language_code
    }).then((response) => {
      this.props.getWhiteLabelsAction(values.language_code);
      var userData = JSON.parse(localStorage.getItem('userData'));
      userData['languageCode'] = values.language_code; 
      localStorage.setItem('userData',JSON.stringify(userData));
      localStorage.setItem('language',values.language_code);
      let data = response.data;
      this.props.hideLoading();

      if (data.status === 1 && data.statusCode === 200) {
        this.props.showMessageBar({status: 'success', text: data.message});
      }
    }).catch(() => {
      this.props.hideLoading();
    });
  }

  /**
   * Render the component view.
   */
  render() {
    let url = this.props.match.url;
    let selectedLanguage = JSON.parse(localStorage.getItem('userData'))['languageCode'] || 'en';
    return (
      <div className="content">
        <div className="app-settings">
          <div className="app-settings__menu">
            <Menu url={url} whiteLabel={this.props.whiteLabel} languages={this.props.client.data.languages}/>
          </div>
          
          <div className="app-settings__content">
            <div className="settings-card">
              <Route exact path={`${url}/edit-profile`} component={ContactInformation} />
              <Route exact path={`${url}/payment-option`} component={PaymentMethods} />
              <Route exact path={`${url}/change-password`} render={() => 
                <ChangePasswordForm onSubmit={this.changePassword} whiteLabel={this.props.whiteLabel}/>
              } />
              <Route exact path={`${url}/change-language`} render={() => 
                <ChangeLanguageForm initialValues={{
                  language_code: selectedLanguage
                }} languages={this.props.client.data.languages} onSubmit={this.changeLanguage} whiteLabel={this.props.whiteLabel}/>
              } />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    whiteLabel:state.whiteLabel,
    client:state.client
  }
}
const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    showLoading, hideLoading, showMessageBar, reset, getWhiteLabelsAction
  }, dispatch);
}
 
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Settings));