import React, { Component } from 'react';

// Import assets
import warningIcon from '../assets/images/warning.png';

// Import components
import PayixButton from '../containers/payix-button';

class DeleteScheduledLoanConfirmation extends Component {
  /**
   * Render the component view.
   */
  render() {
    return (
      <div>
        <div className="modal__header">
          <div className="modal__header-content--centered">
            <img src={warningIcon} alt="" />
          </div>
        </div>

        <div className="modal__body">
          <div className="modal__body-content--pad35 text-center">
            <p className="modal-paragraph">{ this.props.whiteLabel.labels['Scheduled loan delete confirmation message'] }</p>

            <div className="form-group form-group--row-layout">
              <PayixButton className="size-50per margin-right-10" onClick={ this.props.onCancel }>
                  {this.props.whiteLabel.labels['Cancel']}
              </PayixButton>

              <PayixButton className="size-50per margin-right-10" onClick={ this.props.onDelete }>
                  {this.props.whiteLabel.labels['Confirm Delete']}
              </PayixButton>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DeleteScheduledLoanConfirmation;