import React, { Component } from 'react';
import PayixButton from '../../containers/payix-button';
import '../../styles/modules/form.css';
import { Application } from '../../core/app';

class TermsForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      valid: false
    }
  }

  checkClick = (e) => {
    this.setState({
      valid: e.target.checked
    });
  }


  render() {
    const { cancelAction } = this.props;

    return (
      <div>
        <div className="modal__header">
          <h3 className="modal-title common-title">{this.props.whiteLabel.labels['Terms & Conditions']}</h3>
        </div>

        <div className="modal__body terms-conditions">
          <div className="modal__body-content--pad35">
            <p className="terms-conditions__content" dangerouslySetInnerHTML={{__html: this.props.terms}}></p>
            {(this.props.client.hideTAndCPrivacyPolicyLink)
            ? null
            : (
              <p>
                <a target="_blank" rel="noopener noreferrer" href={this.props.privacyPolicy} className="standard-link">
                {this.props.whiteLabel.labels['View privacy policy']}
                </a>
              </p>
              )
            }
            <div className="form-group flex-drow">
              <input id="checkTerms" onClick={this.checkClick} ref={this.termsCheck} type="checkbox" />
              <label htmlFor="checkTerms" className="standard-link margin-left-10">{this.props.whiteLabel.labels['Terms And Conditions Acceptance']}</label>
            </div>

            <div className="form-group form-group--row-layout">
              <PayixButton 
                className="size-50per margin-right-10" 
                disabled={!this.state.valid} 
                type="submit" 
                onClick={() => this.props.OtpFormTrue()}>
                  {this.props.whiteLabel.labels['Proceed']}
              </PayixButton>

              <PayixButton className="size-50per margin-right-10" onClick={cancelAction}>
                  {this.props.whiteLabel.labels['Cancel']}
              </PayixButton>
            </div>
          </div>
        </div>
      </div>
    );
  };
}

export default TermsForm;
