import PropTypes from 'prop-types';
import React, { Component } from 'react';

import SocialLogin from 'react-social-login';

class Button extends Component {
  static propTypes = {
    triggerLogin: PropTypes.func.isRequired
  }

  render () {
    const { children, triggerLogin, ...props } = this.props
    const style = {
      background: '#dd4b39',
      border: '1px solid #dd4b39',
      color: '#FFF',
      fontWeight: 'bold',
      fontSize: '16px',
      borderRadius: '3px',
      padding: '10px 20px',
      width: '50%',
      textAlign: 'center'
    }

    return (
      <div onClick={triggerLogin} style={style} {...props}>
        { children }
      </div>
    )
  }
}

export default SocialLogin(Button)