import React, { Component } from 'react';

class Item extends Component {
  /**
   * Handle the event click over the item.
   *  
   * @param event
   */
  handleItemClick = (event) => {
    this.props.onClick(this.props.value);
  }

  /**
   * Render the component view.
   */
  render() { 
    return (
      <li onClick={ this.handleItemClick }>
        { this.props.children }
      </li>
    );
  }
}
 
export default Item;