import React from 'react';
import { Application } from '../../core/app';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// Import actions
import { showLoading, hideLoading } from '../../actions/app';
import { userSignIn, userSignedIn, getStates } from '../../actions/auth';
import { getClientDetails, getBackgroundImage, getPaymentFrequencies} from '../../actions/client';
import { getWhiteLabelsAction } from '../../actions/white-label';

// Import styles
import '../../styles/layout/home.css';

class Access extends React.Component {
  /**
   * Fetch the client data.
   */
  componentDidMount() {
    this.props.showLoading();
    
    Promise.all([this.props.getBackgroundImage(),  this.props.getClientDetails(), this.props.getPaymentFrequencies(), this.props.getWhiteLabelsAction(this.props.language)])
      .then(() => {
        this.props.hideLoading();
        document.title = this.props.appName;
      })
      .catch(() => {
        Application.showInternalError();
      });
  }

  /**
   * Render the component view.
   */
  render() {
    const { backgroundImageLoaded, backgroundImage } = this.props;

    let bgImageStyle = backgroundImageLoaded
      ? {backgroundImage: `url(${backgroundImage})`}
      : {backgroundImage: 'none'};

    return (
      <div className="home-container">
        <div className="home-image" style={bgImageStyle}></div>

        <div className="home-login-form">
          {this.props.children}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { auth, form, client: {
    data: { appName },
    backgroundImageLoaded,
    backgroundImage
  } } = state;

  return {
    auth,
    form,
    appName,
    backgroundImageLoaded,
    backgroundImage
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    getClientDetails, getPaymentFrequencies, getBackgroundImage, getStates, 
    showLoading, hideLoading, userSignIn, userSignedIn, getWhiteLabelsAction
  }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Access);
