import React, { Component } from 'react';

// Import components
import SendReceiptForm from '../payment/send-receipt-form';
import {connect} from 'react-redux';

class PaymentReceiptDelivery extends Component {
  constructor(props) {
    super(props);

    this.state = {
      details: null,
      email: '',
      phoneNo: '',
    };

    let data = JSON.parse(this.props.data);

    this.state.email = data.installEmailAddress.emailAddrText;
    this.state.phoneNo = data.installMobilePhone.mobilePhoneNo;
  }

  handleSendReceipt = (values) => {
    values.receiptId = this.props.id;
    this.props.onSendReceipt(values);
  }

  /**
   * Render the component view.
   */
  render() {
    let email = this.state.email
    let phoneNo = this.state.phoneNo
    // If auto fill flag is disabled, email and phoneNo will be empty
    if (this.props.client.receiptAutoFillEmailPhone === false) {
      email = ''
      phoneNo = ''
    }
    let initialValues = {email: email, phoneNo: phoneNo};

    return (
      <div className="modal--width-500px">
        <div className="modal__header">
          <h3 className="delivery-receipt-modal__title">{this.props.whiteLabel.labels['Email / SMS Receipt']}</h3>
        </div>

        <div className="modal__body">
          <div className="delivery-receipt-modal__body">
            <p className="delivery-receipt-modal__message">
            {this.props.whiteLabel.labels['Enter recipient email address or mobile phone number below']}
            </p>

            {/* Form to send the receipt to email or phone number */}
            <SendReceiptForm initialValues={initialValues} enableReinitialize={true} onSubmit={ this.handleSendReceipt } whiteLabel={this.props.whiteLabel} />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    client: state.client.data,
    data: localStorage.getItem('userData')
  }
};

export default connect(mapStateToProps)(PaymentReceiptDelivery);
