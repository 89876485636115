export const SIGN_IN = 'SIGN_IN';
export const SET_AUTH_USER_DATA = 'SET_AUTH_USER_DATA';
export const SIGN_UP = 'SIGN_UP';
export const LOG_OUT = 'LOG_OUT';
export const GET_STATES = 'GET_STATES';
export const DISPLAY_TERMS_MODAL = 'DISPLAY_TERMS_MODAL';
export const TOGGLE_TERMS_MODAL = 'TOGGLE_TERMS_MODAL';
export const OTP_FORM_TRUE = 'OTP_FORM_TRUE';
export const OTP_FORM_FALSE = 'OTP_FORM_FALSE';
export const SOCIAL_SIGN_UP = 'SOCIAL_SIGN_UP';
export const VERIFY_USER = 'VERIFY_USER';
export const SET_SOCIAL_LOGIN = 'SET_SOCIAL_LOGIN';
