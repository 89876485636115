import { Application } from './core/app';
import { HOST } from './constants/api';
import store from './config/store';
import { userLogOut } from './actions/auth';
import { showMessageBar } from './actions/app';
import { NETWORK_ERROR, INTERNAL_ERROR } from './constants/errors';

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

/**
 * Next we will register the Base URL with Axios so that all outgoing 
 * HTTP requests automatically have it attached. This is just
 * a simple convenience so we do not have to attach the base url in each 
 * request.
 */

window.axios.defaults.baseURL = HOST;

/**
 * Next we will register the application access token on headers so that all outgoing 
 * HTTP requests automatically have it attached. This is just
 * a simple convenience so we do not have to attach the access token in each 
 * request.
 */
window.axios.defaults.headers.common['accessToken'] = Application.getAccessToken();
window.axios.defaults.timeout = 30000;

/**
 * We configure an axi interceptor to capture errors in which we wish to perform the same logic.
 * In this way we avoid doing the same in each application.
 */
window.axios.interceptors.response.use(undefined, function (error) {
  
  if (!error.response && !error.code) { // Network error
    store.dispatch(showMessageBar({
      status: 'error',
      text: NETWORK_ERROR
    }));
  } else if (error.code === 'ECONNABORTED') { // Timeout request
    store.dispatch(showMessageBar({
      status: 'error',
      text: INTERNAL_ERROR
    }));
  } else {
    /**
     * Error 400, user not authorized to permorm this request.
     * Typically when the access token is invalid or has not been sent in the headers.
     */
    if(error.response.status === 400) {
      store.dispatch(userLogOut());
    }
    if (error.response.status === 401) {
      store.dispatch(userLogOut());
      store.dispatch(showMessageBar({
        status: 'error',
        text: error.response.data.message
      }));
    }

    /**
     * This is the error returned by the api for personalized messages, 
     * maybe some disabled functionality.
     */
    if (error.response.status === 580) {
      store.dispatch(showMessageBar({
        status: 'error',
        text: error.response.data.message
      }));
    }
  }

  return Promise.reject(error);
});