import { ENDPOINTS } from '../constants/api';

/**
 * Request link a loan account.
 * 
 * @param {*} loanAccount - The loan account data.
 */
export function link(loanAccount) {
  let endpoint = ENDPOINTS.linkLoan;

  return window.axios.post(endpoint, loanAccount);
}

/**
 * Request a notify to lender for an account linking.
 * 
 * @param {*} loanAccount - The loan account data.
 */
export function notifyLender(loanAccount) {
  let endpoint = ENDPOINTS.notifyLender;

  return window.axios.post(endpoint, loanAccount);
}

/**
 * Request the user loan accounts.
 */
export function all() {
  let endpoint = ENDPOINTS.listLoan;

  return window.axios.get(endpoint);
}

/**
 * Request all loan account with their details.
 */
export function getLoanAccountsInfo() {
  let endpoint = ENDPOINTS.listAllLoans;

  return window.axios.get(endpoint);
}

/**
 * Request delete a specific loan account.
 * 
 * @param id - The loan account id. 
 */
export function requestDeleteLoanAccount(id) {
  let endpoint = ENDPOINTS.deleteLoan;

  return window.axios.post(endpoint, {
    loanId: id
  });
}

/**
 * Request to upload the user or ar image for a loan account.
 * 
 * @param {*} data 
 */
export function uploadImage(data) {
  let endpoint = ENDPOINTS.loanAccountImageUpload;

  return window.axios.post(endpoint, data);
}

/**
 * Request to get the statements
 * 
 * @param {*} loanId
 */
export function requestStatementListAction(loanId) {
  let endpoint = ENDPOINTS.loanStatementList;
  
  return window.axios.post(endpoint, {loanId: loanId});
}

/**
 * Request to get pdf statements
 * 
 * @param {*} loanId 
 * @param {*} documentId
 */
export function requestPdfStatements(data) {
  let endpoint = ENDPOINTS.loanStatementPdf;

  return window.axios.get(endpoint, data);
}