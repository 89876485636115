import React, { Component } from 'react';
import PayixButton from '../../../containers/payix-button';
import warningIcon from '../../../assets/images/warning.png';

class RemoveCardConfirmation extends Component {
  /**
   * Render the component view.
   */
  render() { 
    return (
      <div>
        <div className="modal__header">
          <div className="modal__header-content--centered">
            <img src={ warningIcon } alt="" />
          </div>
        </div>

        <div className="modal__body">
          <div className="modal__body-content--pad35 text-center">
            { (this.props.hasScheduledPayments) 
              ? (<React.Fragment>
                  <p className="modal-paragraph">
                    {this.props.whiteLabel.labels['Remove payment method linked schedule']}
                  </p>
                  <br /><br />
                </React.Fragment>)
              : null
            }
            
            <p className="modal-paragraph">
              {this.props.whiteLabel.labels['Are you sure you want to remove this payment method?']}
            </p>

            <div className="form-group form-group--row-layout">
              <PayixButton className="size-50per margin-right-10" onClick={this.props.onCancel}>
                  {this.props.whiteLabel.labels['Cancel']}
              </PayixButton>

              <PayixButton className="size-50per margin-right-10" onClick={this.props.onDelete}>
                  {this.props.whiteLabel.labels['Confirm Remove']}
              </PayixButton>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
 
export default RemoveCardConfirmation;