import React, { Component } from 'react';
import dayjs from "dayjs";
import localeData from "dayjs/plugin/localeData";
import PayixButton from '../../containers/payix-button';
import successImg from '../../assets/images/checked.png';

class CongratulationsModal extends Component {

  constructor (props) {
    super(props)
    dayjs.extend(localeData);
    var language = localStorage.getItem('language')
    import(`dayjs/locale/${language}`)
    .then(() => {
        dayjs.locale(language);
        this.forceUpdate();
    });
  }


  /**
   * Render the component view.
   */
  render() {
    let date = dayjs(this.props.date.date) //Day.js includes other languages that moment doesn't have
    
    return (
      <div>
        <div className="modal__header">
          <div className="modal__header-content--centered">
            <img src={ successImg } alt="" />

            <p className="modal-header-text">
              <span className="block gothammedium-font">{this.props.whiteLabel.labels['Congratulations']}</span>
              <span className="op-08">{this.props.whiteLabel.labels['Your payment has been scheduled']}</span>
            </p>
          </div>
        </div>
        
        <div className="modal__body">
          <div className="congratulations-modal__body">
            <div className="congratulations-modal__details">
              <div className="congratulations-modal__payment-details">
                <div>
                  <span className="money money--block money--green congratulations-modal__value">
                    {this.props.amountWithFee}
                  </span>
                  <span className="congratulations-modal__payment-frequency">
                    {this.props.whiteLabel.labels[this.props.frequency]}
                  </span>
                </div>
                <div>
                  <p className="congratulations-modal__card-franchise">
                    {this.props.whiteLabel.labels[this.props.brand]}
                  </p>
                  <span className="congratulations-modal__card-number">
                    **{this.props.lastFour}
                  </span>
                </div>
              </div>
              <div className="congratulations-modal__date-wrapper">
                <p className="congratulations-modal__date">
                  <span>{this.props.whiteLabel.labels['DATE']}</span>
                  <br />
                  <b className="congratulations-modal__month">{date.format('MMM').toUpperCase()}</b>
                  <br />
                  <span>{date.date()}, {date.year()}</span>
                </p>
              </div>
            </div>

            <PayixButton className="button--full" onClick={ this.props.onClose }>
                {this.props.whiteLabel.labels['Close']}
            </PayixButton>
          </div>
        </div>
      </div>
    );
  }
}

export default CongratulationsModal;
