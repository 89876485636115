import * as types from '../constants/actions/loan-account';

const defaultState = {
  status: '0',
  isLoading: true,
  sortBy: 1,
  statementList: []
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case types.SET_STATEMENT_LIST :
      return {...state, statementList: action.payload, isLoading: false}
    case types.LOADING_STATEMENT_LIST :
      return {...state, statementList: [] , isLoading: true}
    default:
      return state;
  }
}