import React, { Component } from 'react';
import { toUSD } from '../../helpers/formatters';
import PayixButton from '../../containers/payix-button';

// Import assets
import warningIcon from '../../assets/images/warning.png';

class EditSchedulePadTerms extends Component {
  /**
   * Render the component view.
   */
  render() {
    let { company } = this.props;

    return (
      <div>
        <div className="modal__header">
          <div className="modal__header-content--centered">
            <img src={ warningIcon } alt="" />
          </div>
        </div>

        <div className="modal__body">
          <div className="modal__body-content--pad35">
            <p className="ach-terms gothambook-font">
              PAD By clicking on <span className="gothammedium-font">“Submit”</span> I hereby authorize <span className="gothammedium-font">{company.name} ({company.website}) </span>
              to initiate an electronic withdrawal from the above indicated bank account in the amount entered (or provided) on this page. 
              I understand that if this transaction is submitted after 5:00 PM Eastern Standard Time, 
              it will have an effective date of no sooner than the next business-banking day and will show as a withdrawal 
              from my account on that date. If I wish to rescind this authorization and cancel this payment, 
              or the amount withdrawn from my account is different than the amount authorized herein, 
              I may call <span className="gothammedium-font">{company.phone}</span> during the following business hours (Business Days and Hours)
              <span className="gothammedium-font">({company.workingHour})</span>. 
              Furthermore, I assert that I am the owner or an authorized signer of the bank account provided.
            </p>

            <div className="form-group form-group--row-layout">
              <p className="size-50per no-margin">Payment Amount: <span className="gothammedium-font">{toUSD(this.props.amount)}</span></p>
              <p className="size-50per no-margin">Bank Account: <span className="gothammedium-font">**{this.props.lastFour}</span></p>
            </div>

            <div className="form-group form-group--row-layout">
              <p className="size-50per no-margin">Payment Start Date: <span className="gothammedium-font">{this.props.scheduleDate}</span></p>
            </div>

            <div className="form-group form-group--row-layout">
              <PayixButton className="size-50per margin-right-10" onClick={ this.props.onCancel }>
                  {this.props.whiteLabel.labels['Cancel']}
              </PayixButton>

              <PayixButton className="size-50per margin-right-10" onClick={ this.props.onSubmit }>
                  {this.props.whiteLabel.labels['Submit']}
              </PayixButton>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
 
export default EditSchedulePadTerms;