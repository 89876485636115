import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Application } from '../../../core/app';
import {
  fetchRecurringPaymentsNumber, getPaymentMessage, requestDeleteAllSchedule,
  emailSchedulePayment, schedulePayment as requestSchedulepayment,
} from '../../../api/payment';
import {
  fetchClientDetails, getDetails, requestResendEmailConfirmation
} from '../../../api/client';
import { paymentFrequency } from '../../../helpers/app';
import { toUSD, checkDecimal } from '../../../helpers/formatters';
import moment from 'moment';
import { 
  ONLYONCE, TWICE_A_MONTH, TWICE_A_MONTH14, PAYMENT_TYPE_CARD, PAYMENT_TYPE_ACH, PAYMENT_TYPE_PAD
} from '../../../constants/app';

// Import actions
import { setPaymentMethod, showLoading, hideLoading } from '../../../actions/app';
import { schedulePayment } from '../../../actions/schedule';

// Import components
import CardTermsConditions from '../../../components/payment/card-terms-conditions';
import AchTermsConditions from '../../../components/payment/ach-terms-conditions';
import PadTermsConditions from '../../../components/payment/pad-terms-conditions';
import CongratulationsScheduleModal from '../../../components/schedule/congratulations-modal';
import EmailReceiptSendModal from '../../../components/schedule/email-receipt-send-modal';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker'
import InfoModal from '../../../components/application/modals/info-modal';
import Modal from '../../../components/application/modal';
import Notice from '../../../components/application/notice';
import PayixButton from '../../../containers/payix-button';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';

class ScheduleForm extends Component {
  constructor(props) {
    super(props);
    this.lang = localStorage.getItem('language');
    import('date-fns/esm/locale').then((response) => {
        const allLangs = Object.keys(response);
        allLangs.forEach((currentLang) => {
          // Registering all the languages for datepicker
          registerLocale(currentLang, response[currentLang]);
        });
        if (response[this.lang]) {
          // Setting default locale
          setDefaultLocale(this.lang);
        } 
    });
    
    
    if (this.props.client.autoFillSchOnetime) {
      let proccessedAmount = this.props.client.autoFillSchOnetime ? parseFloat(this.props.loanAccount.regularPmtAmt).toFixed(2) : '';
      this.setAmount(proccessedAmount.toString());
      this.state = {
        amount: proccessedAmount,
        isModalOpen: false,
        modal: null,
        modalWidth: '500px',
        notice: '',
        paymentFrequency: ONLYONCE,
        scheduleDate: null,
        scheduleDate2: null,
        paymentMessage: null,
        mustAcceptPaymentMessage: false,
        scheduleDetails: null,
        msgSchdPaymentButton: null,
        transactionFee: props.transactionFee,
        emailUser: '',
        emailEntered: '',
      };
    } else {
      this.state = {
        amount: '',
        isModalOpen: false,
        modal: null,
        modalWidth: '500px',
        notice: '',
        paymentFrequency: ONLYONCE,
        scheduleDate: null,
        scheduleDate2: null,
        paymentMessage: null,
        mustAcceptPaymentMessage: false,
        scheduleDetails: null,
        msgSchdPaymentButton: null,
        transactionFee: props.transactionFee,
        emailUser: '',
        emailEntered: '',
      };
    }

    fetchClientDetails()
        .then(response => {
          let dataResponse = response.data;
          this.setState({
            msgSchdPaymentButton: (this.props.whiteLabel.labels['Message Scheduled Payment Button'] != null && this.props.whiteLabel.labels['Message Scheduled Payment Button'] != '') ? this.props.whiteLabel.labels['Message Scheduled Payment Button'] : dataResponse.result.data.msgSchdPaymentButton,
          })
        })

    getPaymentMessage()
    .then(response => {
        let dataResponse = response.data;
        if (dataResponse.result.paymentMessage != null) {
          this.state.paymentMessage = (this.props.whiteLabel.labels['Payment Message Text'] != null && this.props.whiteLabel.labels['Payment Message Text'] != '') ? this.props.whiteLabel.labels['Payment Message Text'] : dataResponse.result.paymentMessage;
          this.state.mustAcceptPaymentMessage = true;
        }
    });
    getDetails()
    .then((response) => {
        let data = response.data;

        if (data.status === 1 && data.statusCode === 200) {
            this.state.detailsUser = data.result;

            var emailPrimary = null,
                emailNotPrimary = null

            this.state.detailsUser.installEmailAddresses.map(email => {
                if (email.isPrimary) {
                    emailPrimary = email.emailAddrText 
                } else if (!email.isPrimary) {
                    emailNotPrimary = email.emailAddrText
                }
            })

            if (emailPrimary || emailNotPrimary) {
                var email = ''
                if (emailPrimary) {
                    email = emailPrimary
                } else {
                    email = emailNotPrimary
                }
                this.state.emailUser = email
            }
        }
    })
    .catch(() => {
    })
  }

  paymentsNumber = null;

  componentDidMount() {
      //Assign datepicker to readonly
      var datePicker = document.getElementsByClassName('datepicker')
      for (var i = 0; i < datePicker.length; i++) {
          datePicker[i].setAttribute('readonly', 'readonly')
      }
  }

  componentDidUpdate(prevProps) {
    // If the selected loan account has changed, then we clean the amount, scheduleDate2
    // and reset the paymentFrequecy to onlyonce.
    if (this.props.loanAccount.id !== prevProps.loanAccount.id) {
      this.setState({
        paymentFrequency: ONLYONCE,
        scheduleDate2: null,
        amount: (this.state.paymentFrequency === ONLYONCE) ? parseFloat(this.state.amount).toFixed(2) : ''
      });
    }

    if (this.props.client.autoFillSchOnetime != prevProps.client.autoFillSchOnetime) {
      let proccessedAmount = this.props.client.autoFillSchOnetime ? parseFloat(this.props.loanAccount.regularPmtAmt).toFixed(2) : '';
      this.setAmount (proccessedAmount.toString());
      this.setState({
        amount: this.props.client.autoFillSchOnetime ? parseFloat(this.props.loanAccount.regularPmtAmt).toFixed(2) : ''
      });
    }

    // (FALLBACK) If payment method changes and the old frequency is not available anymore
    if ((this.props.borSchedRecurringAchOnly == true 
        && this.props.selectedPaymentMethod != null)
      && (this.props.selectedPaymentMethod["paymentType"] == 1 
        && this.state.paymentFrequency != ONLYONCE)) {
        this.setState({
          paymentFrequency: ONLYONCE
        });
    }

  }

  /**
   * Handler for on change event on the amount field.
   * 
   * @param event
   */
  handlePayableAmount = (event) => {
    this.setState({
      amount: event.target.value
    });
    this.props.onChangeAmount(event);
  }

  /**
   * Close the modal.
   */
  closeModal = () => {
      this.setState({
          isModalOpen: false,
          modal: null,
          modalWidth: '500px'
      });
  }

  /**
   * Continue with the execution of the schedule payment
   */
  continueSchedulePayment = email => () => {

      if (email != '' && email != this.state.emailUser) {
          this.setState({
            emailEntered: email
          });
      }

      this.handleSchedulePayment();
  }

  acceptPaymentMessage = () => {
    this.setState({
      mustAcceptPaymentMessage: false,
    });
  };

  /**
   * Close the congratulations modal.
   */
  closeCongratulationsModal = () => {
    this.closeModal();

    this.props.onSuccess();

    if (this.state.emailEntered != '') {
        this.setOtherEmailSchedule(this.state.emailEntered)
        this.setState({
            emailEntered: ''
        });
    }

  }

  /**
   * Display the card terms and conditions modal.
   */
  displayCardTermsConditionsModal() {
    this.setState({
      isModalOpen: true,
      modal: 'CARD_TERMS_CONDITIONS',
      modalWidth: '750px'
    });
  }

  /**
   * Display the ach terms and conditions modal.
   */
  displayAchTermsConditionsModal() {
    this.setState({
      isModalOpen: true,
      modal: 'ACH_TERMS_CONDITIONS',
      modalWidth: '750px'
    });
  }

    /**
   * Display the ach terms and conditions modal.
   */
  displayPadTermsConditionsModal() {
    this.setState({
      isModalOpen: true,
      modal: 'PAD_TERMS_CONDITIONS',
      modalWidth: '750px'
    });
  }

  /**
   * Handler for on change event on the amount field.
   * 
   * @param event
   */
  handleChangeAmount = (event) => {
    this.setAmount(event.target.value);
  }

  /**
   * Set the new value for amount.
   * 
   * @param value
   */
  setAmount = (value) => {
    value = checkDecimal(value);
    if (value) {
      this.setState({amount: value[0]});
    }
  }

  /**
   * Get the total amount to pay.
   */
  getAmount() { 
    let transactionFee = !this.props.transactionFee ? 0 : this.props.transactionFee;
    let amount = (this.state.amount === '') ? 0 : this.state.amount;
    if (((amount == 0) || (typeof amount == 'undefined')) && (this.props.client.autoFillSchOnetime)) {
      amount = this.props.loanAccount.regularPmtAmt;
    }
    let result = parseFloat(amount) + transactionFee;
    return result.toFixed(2);
  }

  /**
   * Check if the form has all required fields full.
   */
  isFormFull() {
    let { amount, paymentFrequency, scheduleDate, scheduleDate2 } = this.state;
    let { selectedPaymentMethod } = this.props;
    if(amount > 0){
      if (parseInt(this.props.client.useLmsSchedules) !== 1 && (this.state.paymentFrequency === TWICE_A_MONTH || this.state.paymentFrequency === TWICE_A_MONTH14)) {
        return (amount && selectedPaymentMethod && paymentFrequency && scheduleDate && scheduleDate2);
      }

      return (amount && selectedPaymentMethod && paymentFrequency && scheduleDate);
    }
  }

  /**
   * Set the selected payment frequency.
   * 
   * @param event
   */
  setPaymentFrequency = (event) => {
    let paymentFrequency = event.target.value;
    let amount = '';
    let scheduleDate = null;
    if ((this.props.client.borSuggestPmtAmtOnlyWhenLoanFreqMatch) && (this.props.loanAccount.pmtFrequency == paymentFrequency)) {
      amount = parseFloat(this.props.loanAccount.regularPmtAmt).toFixed(2);
    } else if (this.props.client.borSuggestPmtAmtOnlyWhenLoanFreqMatch == 0) {
      amount = parseFloat(this.props.loanAccount.regularPmtAmt).toFixed(2);
    } 

    if (paymentFrequency === ONLYONCE) {
        if (this.state.scheduleDate != null) {
            scheduleDate =  null
            this.setState({
                scheduleDate
            });
        }
    }

    this.props.changeFrequency(paymentFrequency)
    this.setState({
      amount,
      paymentFrequency,
      transactionFee: this.props.transactionFee
    });
    if (this.props.borSchedRecurringAchOnly == true && paymentFrequency != 'onlyonce') {
      if (this.props.selectedPaymentMethod == null) {
        this.props.resetSelectedPayment();
      } else if (this.props.selectedPaymentMethod['paymentType'] != 2) {
        this.props.resetSelectedPayment();
      }
    }
  }

  /**
   * Set the selected schedule date.
   * 
   * @param date
   */
  setScheduleDate = (date) => {
    this.setState({
      scheduleDate: date
    });
  }

  /**
   * Set the selected schedule date on blur event.
   * 
   */
  setScheduleDateOnBlur = ({target:{value}})=>{
      if (value != '') {
          this.setState({
              scheduleDate: moment(value)
          });
      }
  }

  /**
   * Set the selected schedule date 2.
   * 
   * @param date
   */
  setScheduleDate2 = (date) => {
    this.setState({
      scheduleDate2: date
    });
  }

  /*
  * Handle the event to show the modal of email receipt
  */
  handleBeforeSchedulePayment = (event) => {
      event.preventDefault();

      let { loanAccount } = this.props;

      if (this.isFormFull()) {
        if (loanAccount.hasScheduled) {
            this.setState({
                  isModalOpen: true,
                  modal: 'HAS_SCHEDULED_PAYMENT',
                  modalWidth: '750px'
            });
        } else {
            this.handleReceiptsBeforeSchedulePayment();
        }
    }
  }

  handleReceiptsBeforeSchedulePayment = () => {
      if (this.state.paymentFrequency === ONLYONCE && this.props.client.comScheduledPaymentEmailReceiptsOneTime == 1) {
          this.setState({
              isModalOpen: true,
              modal: 'EMAIL_RECEIPT_SEND',
              modalWidth: '500px'
          });
      } else if (this.state.paymentFrequency !== ONLYONCE && this.props.client.comScheduledPaymentEmailReceiptsRecurring == 1) {
          this.setState({
              isModalOpen: true,
              modal: 'EMAIL_RECEIPT_SEND',
              modalWidth: '500px'
          });
      } else {
          this.handleSchedulePayment();
      }
  }

  /**
   * Schedule a payment
   * 
   * @event
   */
  handleSchedulePayment = () => {
    
    let { amount, paymentFrequency } = this.state;
    let { loanAccount, selectedPaymentMethod } = this.props;
    if (this.isFormFull()) {
      if (selectedPaymentMethod.paymentType === PAYMENT_TYPE_CARD) {
        if (paymentFrequency !== ONLYONCE) {
          this.props.showLoading();
  
          fetchRecurringPaymentsNumber({
            loanId: loanAccount.id,
            amount,
            freq: paymentFrequency,
          }).then((response) => {
            let dataResponse = response.data;
    
            if (dataResponse.status === 1 && dataResponse.statusCode === 200) {
              // Append the payments number and show the term conditions modal.
              this.paymentsNumber = dataResponse.result.frequency;
              this.displayCardTermsConditionsModal();
            }
  
            this.props.hideLoading();
          }).catch(() => {
            Application.showInternalError();
          });
        } else {
          this.displayCardTermsConditionsModal();
        }
      } else if (selectedPaymentMethod.paymentType === PAYMENT_TYPE_ACH) {
        if (paymentFrequency !== ONLYONCE) {
          this.props.showLoading();
  
          fetchRecurringPaymentsNumber({
            loanId: loanAccount.id,
            amount,
            freq: paymentFrequency,
          }).then((response) => {
            let dataResponse = response.data;
    
            if (dataResponse.status === 1 && dataResponse.statusCode === 200) {
              // Append the payments number and show the term conditions modal.
              this.paymentsNumber = dataResponse.result.frequency;
              this.displayAchTermsConditionsModal();
            }
  
            this.props.hideLoading();
          }).catch(() => {
            Application.showInternalError();
          });
        } else {
          this.displayAchTermsConditionsModal();
        }
      }else if (selectedPaymentMethod.paymentType === PAYMENT_TYPE_PAD) {
        if (paymentFrequency !== ONLYONCE) {
          this.props.showLoading();
  
          fetchRecurringPaymentsNumber({
            loanId: loanAccount.id,
            amount,
            freq: paymentFrequency,
          }).then((response) => {
            let dataResponse = response.data;
    
            if (dataResponse.status === 1 && dataResponse.statusCode === 200) {
              // Append the payments number and show the term conditions modal.
              this.paymentsNumber = dataResponse.result.frequency;
              this.displayPadTermsConditionsModal();
            }
  
            this.props.hideLoading();
          }).catch(() => {
            Application.showInternalError();
          });
        } else {
          this.displayPadTermsConditionsModal();
        }
      }
    }
  }

  handleNotifyPmtLessThanDue = () => {
      let amount = this.getAmount();
      //Show the webpop if payment is less than total amount due
      if (this.props.notifyPmtLessThanDue != '0' && parseFloat(amount).toFixed(2) < parseFloat(this.props.loanAccount.calNextPayment)) {
          this.setState({
              isModalOpen: true,
              modal: 'NOTICE_PMT_LESS_AMT_DUE',
              notice: this.props.whiteLabel.labels['Amount is less than total amount due'],
              modalWidth: '500px'
          })
      } else {
          this.schedulePayment()
      }
  }

  /**
   * Schedule a payment for automatic recurring payment.
   */
  schedulePayment = () => {
    let { paymentFrequency, scheduleDate } = this.state;
    let { loanAccount, selectedPaymentMethod } = this.props;
    let scheduleDate2 = (
      parseInt(this.props.client.useLmsSchedules) !== 1
      && (
        this.state.paymentFrequency === TWICE_A_MONTH 
        || this.state.paymentFrequency === TWICE_A_MONTH14
        )
      ) ? moment(this.state.scheduleDate2).format('YYYY-MM-DD') : '';
    let amount = this.getAmount();

    this.closeModal();
    this.props.showLoading();

    requestSchedulepayment({
      achTransactionFee: '',
      cardTransactionFee: 0,
      id: selectedPaymentMethod.id,
      loanAmount: parseFloat(amount).toFixed(2),
      loanId: loanAccount.id,
      paymentType: selectedPaymentMethod.paymentType,
      scheduleDate1: moment(scheduleDate).format('YYYY-MM-DD'),
      scheduleDate2: scheduleDate2,
      type: paymentFrequency === ONLYONCE ? 1 : 2,
      pmtFreq: paymentFrequency
    })
    .then((response) => {
      let data = response.data;

      this.props.hideLoading();

      if (data.status === 0) {
        this.setState({
          isModalOpen: true,
          modal: 'NOTICE',
          notice: data.message
        });
      } else if (data.status === 1 && data.statusCode === 200) {
        this.setState({
          scheduleDate: null,
          paymentFrequency: ONLYONCE,
          amount: this.props.client.autoFillSchOnetime ? parseFloat(this.props.loanAccount.regularPmtAmt).toFixed(2) : '',
          scheduleDetails: data.result.data,
          isModalOpen: true,
          modal: 'SUCCESSFUL_SCHEDULE'
        });
      }
    })
    .catch(() => {
      Application.showInternalError();
      this.props.hideLoading();
    });
  }

  /**
   * Show the modal confirmation before to remove all the 
   * current scheduled payments active.
   */
  showRemoveRecurringScheduleModal = () => {
    this.setState({
      isModalOpen: true,
      modal: 'REMOVE_RECURRING_SCHEDULE'
    });
  }

  /**
   * Remove all the current active scheduled payments.
   */
  removeRecurringSchedule = () => {
    this.closeModal();
    this.props.showLoading();

    requestDeleteAllSchedule(this.props.loanAccount.id)
      .then((response) => {
        let data = response.data;

        this.props.hideLoading();

        if (data.status === 1 && data.statusCode === 200) {
          Application.showSuccessMessage(data.message);
          this.props.onSuccess();
        } else {
          Application.showErrorMessage(data.message);
        }
      })
      .catch(() => {
        this.props.hideLoading();
        Application.showInternalError();
      })
  }

   /**
   * Map the response back when scheduling a payment and create props that are passed to the success modal.
   * 
   * @param {*} response 
   */
  mapResponseToPropsScheduleSuccessModal() {
    let { scheduleDetails } = this.state;
    let props = {};

    if (scheduleDetails !== null) {
      props = {
        frequency: scheduleDetails.paymentText,
        amount: toUSD(scheduleDetails.pmtAmt),
        amountWithFee: toUSD(parseFloat(this.props.transactionFee) + scheduleDetails.pmtAmt),
        lastFour: (scheduleDetails.paymentType === 1) 
        ? scheduleDetails.cardMethod.lastFour
        : (scheduleDetails.paymentType === 2)
        ? scheduleDetails.achMethod.lastFour
        : scheduleDetails.padMethod.lastFour,
        brand: (scheduleDetails.paymentType === 1) 
          ? scheduleDetails.cardMethod.cardBrand
          : (scheduleDetails.paymentType === 2)
          ? scheduleDetails.achMethod.bankName
          : scheduleDetails.padMethod.bankName,
        date: scheduleDetails.nextExecutionDate
      }
    }

    return props;
  }

  /**
   * Resend the link to email confirmation.
   */
  resendLink = () => {
    this.props.showLoading();

    this.state.detailsUser.installEmailAddresses.map(email => {
        if (!email.isVerified) {
          requestResendEmailConfirmation(email.id)
            .then((response) => {
                this.props.hideLoading();
      
                let data = response.data;
      
                if (data.status === 1 && data.statusCode === 200) {
                    Application.showSuccessMessage(data.message);
                } else {
                    Application.showErrorMessage(data.message);
                }
            })
            .catch(() => {
              this.props.hideLoading();
            });
          }
    })

    
  }

  setOtherEmailSchedule = (email) => {
      let {loanAccount} = this.props;
      
      emailSchedulePayment({
          loan_id: loanAccount.id,
          receipt_email_address: email
      })
      .then((response) => {
          let data = response;

          if (data.status === 1 && data.statusCode === 200) {
              Application.showSuccessMessage(data.message);
          } else {
              Application.showErrorMessage(data.message);
          }
      })
      .catch(() => {
      });
  }

  renderScheduleDate2(minDate) {
    if (parseInt(this.props.client.useLmsSchedules) !== 1 && (this.state.paymentFrequency === TWICE_A_MONTH || this.state.paymentFrequency === TWICE_A_MONTH14)) {
      return (
        <div className="form-group loan-account__schedule-date">
          <label>{this.props.whiteLabel.labels['Schedule Date 2']}</label>
          <DatePicker
            selected={this.state.scheduleDate2}
            onChange={this.setScheduleDate2}
            className="datepicker"
            minDate={new Date(minDate)}
            dateFormat="MM/dd/yyyy"
          />
        </div>
      )
    }

    return null;
  }

  /**
   * Render the input for enter the amount.
   */
  renderAmountInput() {
    let extraProps = {};

    if (this.props.schedulePayment) {
      extraProps.disabled = this.props.frequency === ONLYONCE ? false : true
    }

    return (
      <input 
        className="money-input__textfield" 
        type="text" maxLength="13"
        value={this.state.amount} 
        onChange={this.handleAmount}
        {...extraProps} />
    )
  }

  /**
   * Render the Card terms and contidions modal.
   * 
   * @param {*} data 
   */
  renderCardTermsAndConditionsModal() {
    let { client, selectedPaymentMethod } = this.props;
    let props = {
      amount: this.getAmount(),
      company: {
        name: client.legalCompanyName,
        website: client.website,
        phone: client.phoneNumber,
        workingHour: client.workingHour
      },
      disclaimer:{
        borrowerOnetimeCardDisclosure: client.borrowerOnetimeCardDisclosure,
        borrowerRecurringCardDisclosure: client.borrowerRecurringCardDisclosure,
        borrowerOnetimeAchDisclosure: client.borrowerOnetimeAchDisclosure,
        borrowerRecurringAchDisclosure: client.borrowerRecurringAchDisclosure
      },
      isSchedule: true,
      lastFour: selectedPaymentMethod ? selectedPaymentMethod.lastFour : null,
      scheduleDate: moment(this.state.scheduleDate).format('YYYY-MM-DD'),
      frequency: this.state.paymentFrequency,
      paymentsNumber: this.paymentsNumber,
      onSubmit: this.handleNotifyPmtLessThanDue
    };

    return (
      <CardTermsConditions 
        {...props}
        useLmsSchedules={this.props.client.useLmsSchedules}
        enableLmsUi={this.props.client.enableLmsUi}
        onCancel={this.closeModal}
        whiteLabel={this.props.whiteLabel} />
    )
  }

  /**
   * Render the Ach terms and contidions modal.
   * 
   * @param {*} data 
   */
  renderAchTermsAndConditionsModal() {
    let { client, selectedPaymentMethod } = this.props;

    let props = {
      amount: this.getAmount(),
      company: {
        name: client.legalCompanyName,
        website: client.website,
        phone: client.phoneNumber,
        workingHour: client.workingHour
      },
      disclaimer:{
        borrowerOnetimeCardDisclosure: client.borrowerOnetimeCardDisclosure,
        borrowerRecurringCardDisclosure: client.borrowerRecurringCardDisclosure,
        borrowerOnetimeAchDisclosure: client.borrowerOnetimeAchDisclosure,
        borrowerRecurringAchDisclosure: client.borrowerRecurringAchDisclosure
      },
      isSchedule: true,
      oneTimeAchImportNls: client.oneTimeAchImportNls,
      lastFour: selectedPaymentMethod ? selectedPaymentMethod.lastFour : null,
      scheduleDate: moment(this.state.scheduleDate).format('MM/DD/YYYY'),
      frequency: this.state.paymentFrequency,
      paymentsNumber: this.paymentsNumber,
      onSubmit: this.handleNotifyPmtLessThanDue
    };

    return (
      <AchTermsConditions 
        {...props}
        useLmsSchedules={this.props.client.useLmsSchedules}
        enableLmsUi={this.props.client.enableLmsUi}
        onCancel={this.closeModal}
        whiteLabel={this.props.whiteLabel} />
    )
  }

  /**
   * Render the Ach terms and contidions modal.
   * 
   * @param {*} data 
   */
  renderPadTermsAndConditionsModal() {
    let { client, selectedPaymentMethod } = this.props;

    let props = {
      amount: this.getAmount(),
      company: {
        name: client.legalCompanyName,
        website: client.website,
        phone: client.phoneNumber,
        workingHour: client.workingHour
      },
      isSchedule: true,
      lastFour: selectedPaymentMethod ? selectedPaymentMethod.lastFour : null,
      scheduleDate: moment(this.state.scheduleDate).format('MM/DD/YYYY'),
      frequency: this.state.paymentFrequency,
      paymentsNumber: this.paymentsNumber,
      onSubmit: this.handleNotifyPmtLessThanDue
    };

    return (
      <PadTermsConditions 
        {...props}
        useLmsSchedules={this.props.client.useLmsSchedules}
        enableLmsUi={this.props.client.enableLmsUi}
        onCancel={this.closeModal}
        whiteLabel={this.props.whiteLabel} />
    )
  }

  /**
   * Render the email receipt send modal
   */
  renderEmailReceiptSendModal() {

      if (this.state.emailUser != '') {
          return <EmailReceiptSendModal 
            email={this.state.emailUser}
            whiteLabel={this.props.whiteLabel}
            onCancel={this.closeModal}
            onNext={this.continueSchedulePayment} />;
      }
      
  }

  renderModalContent() {
    if (this.state.mustAcceptPaymentMessage) {
      if (
          this.state.modal === 'ACH_TERMS_CONDITIONS' 
          || this.state.modal === 'PAD_TERMS_CONDITIONS' 
          || this.state.modal === 'CARD_TERMS_CONDITIONS'
        ) {
        return (
            <InfoModal
                content={this.state.paymentMessage}
                leftButtonHandler={this.closeModal}
                rightButtonHandler={this.acceptPaymentMessage}
                whiteLabel={this.props.whiteLabel}
            />
        );
      } else if (this.state.modal === 'PAYMENT_METHOD') {
        return (
            <InfoModal
                content={this.state.paymentMessage}
                leftButtonHandler={this.closeModal}
                rightButtonHandler={this.schedulePayment}
                whiteLabel={this.props.whiteLabel}
            />
        );
      }
    }
    switch (this.state.modal) {
      case 'SUCCESSFUL_SCHEDULE' :
        return <CongratulationsScheduleModal
            {...this.mapResponseToPropsScheduleSuccessModal()}
            onClose={ this.closeCongratulationsModal }
            whiteLabel={this.props.whiteLabel} />
      case 'CARD_TERMS_CONDITIONS' :
        return this.renderCardTermsAndConditionsModal();
      case 'ACH_TERMS_CONDITIONS' :
        return this.renderAchTermsAndConditionsModal();
      case 'PAD_TERMS_CONDITIONS' :
        return this.renderPadTermsAndConditionsModal();
      case 'EMAIL_RECEIPT_SEND' :
        return this.renderEmailReceiptSendModal();
      case 'REMOVE_RECURRING_SCHEDULE' :
        return <InfoModal
          content={this.props.whiteLabel.labels['Are you sure you want to delete all the scheduled payments?']}
          leftButtonHandler={this.closeModal}
          rightButtonHandler={this.removeRecurringSchedule}
          whiteLabel={this.props.whiteLabel} />;
      case 'NOTICE_PMT_LESS_AMT_DUE' :
        return <InfoModal 
          content={this.state.notice}
          leftButtonHandler={this.closeModal}
          rightButtonHandler={this.schedulePayment}
          whiteLabel={this.props.whiteLabel} />
      case 'NOTICE' :
        return <Notice 
          message={this.state.notice}
          onClose={this.closeModal}
          whiteLabel={this.props.whiteLabel} />;
      case 'HAS_SCHEDULED_PAYMENT' :
          return (
            <InfoModal
                content={(this.props.whiteLabel.labels['Message Scheduled Payment'] != null && this.props.whiteLabel.labels['Message Scheduled Payment'] != '') ? this.props.whiteLabel.labels['Message Scheduled Payment'] : this.props.client.messageScheduledPayment}
                leftButtonHandler={this.closeModal}
                rightButtonHandler={this.handleReceiptsBeforeSchedulePayment}
                whiteLabel={this.props.whiteLabel}
            />
          );
      default :
        return null;
    }
  }

  /**
   * Render the component view.
   */
  render() {

    let paymentMethodsConditional;
    if (this.props.borSchedRecurringAchOnly == true && this.state.paymentFrequency != 'onlyonce') {
      paymentMethodsConditional = this.props.paymentMethodsOnlyACH;
    } else {
      paymentMethodsConditional = this.props.paymentMethods;
    }

    let minDate,
    maxDate = null;
    if ((this.props.client.borAchScheduleBizDays > 0) && (this.props.selectedPaymentMethod)) {
      if (this.props.selectedPaymentMethod.paymentType == 2) {
        var businessDays = parseInt(this.props.client.borAchScheduleBizDays);
        var days = businessDays + Math.floor((Math.min(moment().day(),5)+businessDays)/6)*2;
        minDate = moment().add(days, 'days');
      } else {
        minDate = moment().add(1, 'days');
        maxDate = null;
      }
      
    } else {
      minDate = moment().add(1, 'days');
      maxDate = null;
    }

    
    if (this.props.borSchedMaxDays != undefined && this.props.borSchedMaxDays != '0' && this.state.paymentFrequency == 'onlyonce') {
        maxDate = moment().add(parseInt(this.props.borSchedMaxDays), 'days');
    }
    let paymentFreqsConditional = this.props.paymentFreqs;
    if (this.props.borSchedRecurringAchOnly == true && this.props.selectedPaymentMethod != null) {
      if (this.props.selectedPaymentMethod["paymentType"] == 1) {
        paymentFreqsConditional = [];
      }
    }

    return (
      <div>
        <form onSubmit={this.handleBeforeSchedulePayment}  className="load-account__payment-form">
          { paymentMethodsConditional }

          <div className="loan-account_schedule-fields">
            <div className="form-group loan-account__schedule-date">
              <label>{this.props.whiteLabel.labels['Schedule Date']}</label>
              {(maxDate != null)?
                  <DatePicker
                      selected={this.state.scheduleDate}
                      onChange={this.setScheduleDate}
                      className="datepicker"
                      minDate={new Date(minDate)}
                      maxDate={new Date(maxDate)}
                  /> :
                  <DatePicker
                      selected={this.state.scheduleDate}
                      onChange={this.setScheduleDate}
                      className="datepicker"
                      minDate={new Date(minDate)}
                  />
              }
            </div>

            <div className="form-group loan-account__payment-frequency">
              <label>{this.props.whiteLabel.labels['Payment Frequency']}</label>
              <select 
                className="form-group__input custom-select" 
                onChange={this.setPaymentFrequency}
                value={this.state.paymentFrequency}>
                {(this.props.paymentFreqs != null && this.props.client.allowAllBorrowerFrequencies) ? (this.props.paymentFreqs.map((freq, index) => 
                (this.props.loanAccount.pmtFrequency === freq.payment_frequency) ? 
                (this.props.loanAccount.pmtFrequency !== 'S4' && freq.payment_frequency === 'S4') ? 
                 ('') : 
                 (<option key={index} value={freq.payment_frequency}>
                  *{(this.props.whiteLabel.labels[freq.label]) ? this.props.whiteLabel.labels[freq.label] : freq.label}*
                </option>) : 
                ((this.props.loanAccount.pmtFrequency !== 'S4') && (freq.payment_frequency === 'S4')) ?
                ('') : 
                (((this.props.loanAccount.pmtFrequency === 'S4') && (freq.payment_frequency === 'SM')) ? 
                ('') : 
                (<option key={index} value={freq.payment_frequency}>
                  {(this.props.whiteLabel.labels[freq.label]) ? this.props.whiteLabel.labels[freq.label] : freq.label}
                </option>) 
                ))):(this.props.client.isRecurring ? (<option value={this.props.loanAccount.pmtFrequency}>
                  {(this.props.whiteLabel.labels[paymentFrequency(this.props.loanAccount.pmtFrequency, this.props.paymentFreqs)]) ? this.props.whiteLabel.labels[paymentFrequency(this.props.loanAccount.pmtFrequency, this.props.paymentFreqs)] : paymentFrequency(this.props.loanAccount.pmtFrequency, this.props.paymentFreqs)}
                </option>) : (''))}
                <option value={ONLYONCE}>{this.props.whiteLabel.labels['Only Once']}</option>
              </select>
            </div>

            {this.renderScheduleDate2(minDate)}
          </div>

          <div className="form-group">
            <label className="load-account__money-amount">{this.props.whiteLabel.labels['Enter amount you would like to pay']}</label>
            <div className="money-input">
              <span className="money-input__addon">
                <i className="fas fa-dollar-sign"></i>
              </span>

              {/**
                ** Render the input for the amount.
                **/}
              <input 
                className="money-input__textfield" 
                type="text" 
                maxLength="13"
                value={this.state.amount}
                disabled={this.props.editAmount?false:this.state.paymentFrequency !== ONLYONCE ? true : false}
                onChange={this.handlePayableAmount} />
            </div>
          </div>

          {/**
            ** Show the convenience fee if apply. 
            **/}
            {(this.props.transactionFee)
            ? (
              <div className="load-account__total">
                <span>
                  {this.props.whiteLabel.labels['Convenience Fee']}&nbsp;
                  <Tooltip placement="top" overlay={this.props.transactionMargin}>
                    <i className="fas fa-info-circle color-green op-4"></i>
                  </Tooltip>
                </span>
                <span className="color-red">{toUSD(this.props.transactionFee)}</span>
              </div>
            )
            : null
          }

          <div className="load-account__total">
            <span className="gothammedium-font">{this.props.whiteLabel.labels['Total Payable']}</span>
            <span className="gothammedium-font">{toUSD(this.getAmount())}</span>
          </div>

          <PayixButton 
            className="button--full"
            type="submit"
            disabled={!this.isFormFull()}>
            {this.props.whiteLabel.labels['schedule a payment']} {toUSD(this.getAmount())}
          </PayixButton>

          {this.state.msgSchdPaymentButton !== ''
              ? this.state.msgSchdPaymentButton !== null
                  ?
                    (
                      <div className="load-account__disclaimer">
                        <span className="gothambook-font">{this.state.msgSchdPaymentButton}</span>
                      </div>
                    )
                  : null
              : null
          }

          {this.props.showRemoveRecurring
            ? this.props.hasRecurring
              ? this.props.delete
                ? (
                  <button
                    className="button button--full button--transparent"
                    type="button"
                    onClick={this.showRemoveRecurringScheduleModal}>
                    {this.props.whiteLabel.labels['Remove Recurring All Schedules']}
                  </button>
                )
                : null
              : null
            : null
          }
        </form>

        <Modal 
          isOpen={this.state.isModalOpen}
          width={this.state.modalWidth}
          onClose={this.closeModal}>

          {this.renderModalContent()}
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    client: state.client.data,
    paymentFreqs: state.client.paymentFreqs,
    transactionFee: state.app.payment.transactionFee,
    transactionMargin: state.client.transactionMargin,
    whiteLabel:state.whiteLabel,
    borSchedRecurringAchOnly: state.client.data.borSchedRecurringAchOnly,
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    setPaymentMethod, showLoading, hideLoading, schedulePayment
  }, dispatch);
}
 
export default connect(mapStateToProps, mapDispatchToProps)(ScheduleForm);
