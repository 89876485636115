import { ENDPOINTS } from '../constants/api';

/**
 * Request to make a payment.
 * 
 * @param {*} payment 
 */
export function makePayment(payment) {
  let endpoint = ENDPOINTS.makePayment;

  return window.axios.post(endpoint, payment);
}

/**
 * Request to make a schedule for a payment.
 * 
 * @param {*} data The schedule data.
 */
export function schedulePayment(data) {
  let endpoint = ENDPOINTS.paymentSchedule;

  return window.axios.post(endpoint, data);
}

/**
 * Request to send a email notification for schedule a payment.
 * 
 * @param {*} data The schedule data.
 */
export function emailSchedulePayment(data) {
  let endpoint = ENDPOINTS.paymentEmailSchedule;

  return window.axios.post(endpoint, data);
}

/**
 * Request to edit an specific schedule payment.
 */
export function editScheduledPayment(data) {
  let endpoint = ENDPOINTS.editSchedule;

  return window.axios.post(endpoint, data);
}

/**
 * Calculate the recurring payments number for a loan account.
 * 
 * @param {*} data 
 */
export function fetchRecurringPaymentsNumber(data) {
  let endpoint = ENDPOINTS.scheduleFrequency;

  return window.axios.post(endpoint, data);
}

/**
 * Request to send the receipt to an email or phone number.
 * 
 * @param data - The email, phone number and the receipt id.
 */
export function sendReceipt(data) {
  let endpoint = ENDPOINTS.sendPaymentReceipt;

  return window.axios.post(endpoint, data);
}

/**
 * Request to get all receipts.
 *
 * @param {*} options
 */
export function getReceipts(options) {
  let endpoint = ENDPOINTS.getReceipts;

  return window.axios.post(endpoint, options);
}

/**
 * Request to get payment message.
 */
export function getPaymentMessage() {
  let endpoint = ENDPOINTS.getPaymentMessage;
  return window.axios.get(endpoint);
}

/**
 * Request to get all scheduled payments.
 * 
 * @param {*} options 
 */
export function fetchScheduledPayments(options) {
  let endpoint = ENDPOINTS.scheduleList;

  return window.axios.post(endpoint, options);
}

/**
 * Request to delete a specific scheduled payment.
 * 
 * @param {*} id 
 */
export function requestDeleteScheduledPayment(data) {
  let endpoint = ENDPOINTS.deleteSchedule;

  return window.axios.post(endpoint, data);
}

/**
 * Request to delete all scheduled payments active.
 * 
 * @param loanId
 */
export function requestDeleteAllSchedule(loanId) {
  let endpoint = ENDPOINTS.deleteAllScheduled;

  return window.axios.post(endpoint, {
    loanId
  });
}

/**
 * Request the convenience fee applied to a payment method.
 * 
 * @param {*} loanId 
 * @param {*} cardBrand
 * @param {*} paymentType
 * @parm {*} type
 */
export function requestConvenienceFee(loanId, cardBrand, paymentType, type) {
  let endpoint = ENDPOINTS.getConvenienceFee + '?loanId=' + loanId + '&cardBrand=' + cardBrand + '&paymentType=' + paymentType + '&type=' + type;

  return window.axios.get(endpoint);
}