import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { requestAddPhone } from "../../../api/client";
import { INTERNAL_ERROR } from "../../../constants/errors";
import OtpInput from "react-otp-input";
import PayixButton from "../../../containers/payix-button";
import { verifyPhone } from "../../../actions/auth";
import { showMessageBar, showLoading, hideLoading } from "../../../actions/app";
import { resendOtpCode } from '../../../api/auth';
class VerifyPhone extends Component {
  constructor(props) {
      super(props);
      this.state = {
          otp: "",
          hasErrored: false,
          resendUntil: 180
      };
  }

  componentDidMount() {
      this.startCounter();
  }

  handleOtpChange = (otp) => {
    this.setState({ otp , hasErrored: false });
  };
  handleFormSubmit = (event) => {
      event.preventDefault();

      let data = {}
      //This is the process of the addition of the phone number on NLS
      data = {
          id: this.props.loanId,
          phoneno: this.props.phoneNumber.replace("+",""),
          otp: this.state.otp
      }

      this.props.verifyPhone(data)
      .then((data) => {
          if (data) {
              this.props.onSuccess();
          }
      })
      .catch((err) => {
          this.setState({
              otp: "",
              hasErrored: true
          })
          this.props.onError();
      });
      return false;
  };

  /**
   * Start the timer that validates the lifetime of the otp code.
   */
  startCounter() {
      const comp = this;

      this.timerId = setInterval(function() {
          if (comp.state.resendUntil > 1) {
            comp.setState({resendUntil: (comp.state.resendUntil - 1)});
          } else {
            clearInterval(comp.timerId);
            comp.setState({resendUntil: 0});
          }
      }, 1000);
  }

  addPhone = (values) => {
    this.props.showLoading();

    requestAddPhone(values)
      .then((response) => {
        this.props.hideLoading();

        let data = response.data;

        if (data.status === 1 && data.statusCode === 200) {
          this.props.onSuccess();
        } else {
          this.props.onError(data.message);
        }
      })
      .catch((e) => {
        console.log(INTERNAL_ERROR);
        this.props.hideLoading();
        showMessageBar({
          status: "error " + e.toString(),
          text: INTERNAL_ERROR,
        });
        // this.props.onError(INTERNAL_ERROR);
      });
  };

  /**
   * Send the otp code again.
   */
  resendCode = () => {
      let values = this.props;
      this.props.showLoading();

      resendOtpCode(null, values.phoneNumber)
        .then(response => {
            let data = response.data;

            this.props.hideLoading();

            if (
              data.status === 1 
              && data.statusCode === 200
            ) {
                this.props.showMessageBar({
                    status: 'success',
                    text: data.message
                });

                this.setState({resendUntil: 180}, () => {
                    this.startCounter();
                });
            } else {
                this.props.showMessageBar({
                    status: 'error',
                    text: data.message
                });
            }
        })
        .catch(() => {
            this.props.hideLoading();
            this.props.showMessageBar({
                status: 'error',
                text: INTERNAL_ERROR
            });
        });
  }

  /**
   * Render the component view.
   */
  render() {

    return (
      <div>
        <div className="modal__header">
          <h3 className="modal-title">{ this.props.whiteLabel.labels['Verify'] } { this.props.whiteLabel.labels['Phone Number'] }</h3>
        </div>

        <div className="modal__body">
          <div className="modal__body-content--pad35 text-center">
            {/* <AddPhoneForm onSubmit={this.addPhone} /> */}
            <form onSubmit={this.handleFormSubmit}>
              <label>
                <OtpInput
                  value={this.state.otp}
                  inputStyle={{
                    width: "2rem",
                    height: "2rem",
                    margin: "0 1rem",
                    fontSize: "1.2rem",
                    borderRadius: 4,
                    border: "1px solid rgba(0,0,0,0.3)",
                  }}
                  numInputs={6}
                  isDisabled={false}
                  hasErrored={this.state.hasErrored}
                  errorStyle="error"
                  onChange={this.handleOtpChange}
                  separator={<span> </span>}
                  isInputNum={true}
                  shouldAutoFocus
                />
              </label>
              <br />
              <br />

              {this.state.resendUntil > 0 && 
                  <div className="form-group">
                      <p className="em-vertical-margin text-center">
                        <span>{ this.props.whiteLabel.labels['Resend code will enable in'] } {this.state.resendUntil} { this.props.whiteLabel.labels['seconds'] }</span>
                      </p>
                  </div>
              }
                
              <div className="form-group">
                  <PayixButton
                      className="button--full"
                      type="submit"
                      disabled={this.state.otp.toString().length < 6}
                  >
                    { this.props.whiteLabel.labels['Verify'] }
                  </PayixButton>
              </div>
              
              { this.state.resendUntil === 0 && 
                  <div className="form-group">
                      <p className="em-vertical-margin text-center">
                        <span className="form-link gmedium-common" onClick={this.resendCode}>{ this.props.whiteLabel.labels['Resend Code?'] }</span>
                      </p>
                  </div>
              }
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { auth, whiteLabel } = state;
  return {
    otpMessage: auth.otpMessage,
    whiteLabel: whiteLabel
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      verifyPhone,
      showLoading,
      hideLoading,
      showMessageBar,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(VerifyPhone);
