import React, { Component } from 'react';
import warningIcon from '../../assets/images/warning.png';
import PayixButton from '../../containers/payix-button';

class Notice extends Component {
  /**
   * Render the component view.
   */
  render() { 
    return (
      <div>
        <div className="modal__header">
          <div className="modal__header-content--centered">
            <img src={ warningIcon } alt="" />
          </div>
        </div>

        <div className="modal__body">
          <div className="modal__body-content--pad35 text-center">
            <p className="modal-paragraph">
              { this.props.message }
            </p>

            <PayixButton className="button--full" onClick={ this.props.onClose }>
                {this.props.whiteLabel.labels['Close']}
            </PayixButton>
          </div>
        </div>
      </div>
    );
  }
}
 
export default Notice;