import React, { Component } from 'react';
import { Application } from '../core/app';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker'

// Import actions
import { getStates, checkUserSignUp, getSocialSignUp, setMyInfoSocialNetwork } from '../actions/auth';
import { getClientDetails } from '../actions/client';
import { getWhiteLabelsAction } from '../actions/white-label';

// Import components
import Modal from '../components/application/modal';
import SignUpForm from '../components/auth/signup-form';
import TermsForm from '../components/auth/terms-form';

class SignUp extends Component {
  state = {
    isModalOpen: false,
    terms: ''
  }

  /**
   * Get the locations.
   */
  componentDidMount() {
    if (!this.props.data.type) {
      this.props.setMyInfoSocialNetwork({
        type: 3
      });
    }

    this.props.getStates();
  }

  closeModal = () => {
    this.setState({
      isModalOpen: false
    });
  }

  /**
   * Check if the data is valid and show the terms and conditions.
   * 
   * @param values - The user data.
   */
  checkUserSignUp = values => {
    let { data } = this.props;

    values.type = data.type;
    values.socialId = (data.type !== 3) ? data.socialId : '';

    this.props.checkUserSignUp(values)
      .then((terms) => {
        if (terms) {
          this.setState({
            isModalOpen: true,
            terms: terms
          });
        }
      })
      .catch(() => {
        Application.showInternalError();
      });
  }

  /**
   * Submit the sign up data for register the user.
   * 
   * @values
   */
  submitSignUp = () => {
    let { form, data } = this.props;
    let formValues = {};
    
    if (form && form.signup) {
      formValues = form.signup.values;
      formValues.type = data.type;
      formValues.socialId = (data.type !== 3) ? data.socialId : '';
    }

    this.setState({
      isModalOpen: false,
      terms: ''
    });

    this.props.getSocialSignUp(formValues)
      .then((canShowOTP) => {
        if (canShowOTP) {
          this.props.history.push('/verifyOTP');
        }
      })
      .catch(() => {
        Application.showInternalError();
      });
  }


  handleLanguageChange = (ev) => {
    this.setState({appLanguage : ev.target.value});
    localStorage.setItem('language', ev.target.value);
    this.props.getWhiteLabelsAction(ev.target.value);
    this.props.getClientDetails(ev.target.value);
    setDefaultLocale(ev.target.value); // Setting this when language gets changed from signup form
  }

  /**
   * Render the component view.
   */
  render() {
    let { states, client, data } = this.props;

    let initialValues = {
      fname: data.fname ? data.fname : '',
      lname: data.lname ? data.lname : '',
      email: data.email ? data.email : '',
      phoneno: data.phoneno ? data.phoneno : '',
      stateid: data.stateid ? data.stateid : '',
      language: data.language ? data.language : '',
      //dob: data.dob ? data.dob.format : '',
      ssn: data.ssn ? data.ssn : '',
      socialId: data.socialId ? data.socialId : '',
      language: this.props.language
    }
    
    return (
      <div>
        <p className="sign-up-link__container">
          {this.props.whiteLabel.labels['Have an account']}&nbsp;
          <Link to="/login" className="sign-up-link">{this.props.whiteLabel.labels['Sign In']}</Link>
        </p>

        <div className="sign-in-form__container">

          <div className="logo-img__container">
            <img className="logo-img" src={client.imageName} alt="Logo" />
          </div>

          <h1 className="common-title">{this.props.whiteLabel.labels['Create Account']}</h1>
        
          <SignUpForm 
            onSubmit={this.checkUserSignUp} 
            states={states} 
            isSsn={client.isSsn}
            disableQuickLink={client.disableQuickLink}
            initialValues={initialValues}
            whiteLabel={this.props.whiteLabel}
            selectedLanguage={this.props.language} 
            languages={client.languages}
            onLanguageChange={this.handleLanguageChange}
          />

          <Modal 
            isOpen={this.state.isModalOpen}
            width="750px"
            onClose={this.closeModal}>
            <TermsForm 
              terms={this.state.terms}
              OtpFormTrue={this.submitSignUp} 
              cancelAction={this.closeModal}
              client={client}
              whiteLabel={this.props.whiteLabel}
              privacyPolicy={client.privacyPolicy}
            />
          </Modal>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  const { form } = state;

  return {
    client: state.client.data,
    states: state.auth.states,
    data: state.auth.formValues,
    whiteLabel: state.whiteLabel ,
    form
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    getStates, checkUserSignUp, getSocialSignUp, setMyInfoSocialNetwork, getWhiteLabelsAction, getClientDetails
  }, dispatch);
}
 
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SignUp));